import { Navigate, Outlet, Link } from "react-router-dom";
import { useAuth } from "../services/auth.service";

export const HomeLayout = () => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/dashboard/chat" />;
  }

  return (
    <div>
      <Outlet />
    </div>
  )
};