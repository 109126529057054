import { Contact } from "./Contact";
import SearchChat from "./SearchChat";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from "react";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Contacts(props) {
    const { contacts, unreadContacts, sentContacts, automatedContacts, loadContactMessages, search, handleSearch, setLoading } = props;

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="flex flex-col w-5/5 md:w-2/5 border-r-2 h-[calc(100vh-100px)] md:h-[calc(100vh-84px)] md:block" id="contactsContainer">
            <SearchChat onChange={(e) => handleSearch(e)} search={search} />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Principal" {...a11yProps(0)} />
                        <Tab label="Enviados" {...a11yProps(1)} />
                        <Tab label="No Leidos" {...a11yProps(2)} />
                        <Tab label="Res Autom." {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div className="overflow-y-scroll h-[calc(100vh-190px)] md:h-[calc(100vh-190px)]">
                        {contacts.length > 0 ?
                            contacts.map((element, index) => {
                                try {
                                    if (element.loadMore)
                                        return (
                                            <div
                                                onClick={(e) => props.loadMoreContacts(e, 'inbox')}
                                            >
                                                <div
                                                    key={index}
                                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2 hover:bg-gray-200 cursor-pointer"
                                                >
                                                    <div className="w-full text-center pl-2">
                                                        <div className="font-semibold">Cargar más</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    const contact = element;
                                    const lastMessage = element.lastMessage ? element.lastMessage : { text: { body: "" }, timestamp: "" };
                                    if (contact)
                                        return (
                                            <div
                                                onClick={() => {
                                                    loadContactMessages(index);
                                                    element.selected = true;
                                                    setLoading(true);
                                                }}
                                            >
                                                <Contact
                                                    key={index}
                                                    name={
                                                        contact.NombreCompleto
                                                    }
                                                    draft={element.draft ?? ""}
                                                    lastMessage={
                                                        lastMessage.text ? ((typeof lastMessage.text.body === 'string') ? lastMessage.text.body : lastMessage.text.body.name) : lastMessage.type
                                                    }
                                                    lastMessageTimestamp={
                                                        lastMessage.timestamp ? lastMessage.timestamp : ""
                                                    }
                                                    newMessages={contact.newMessages}
                                                />
                                            </div>
                                        );
                                    return <></>;
                                } catch (error) {
                                    return <></>;
                                }
                            }) : <>
                                <div
                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2"
                                >
                                    <div className="w-full text-left pl-2">
                                        <div className="font-semibold">No tiene conversaciones asignadas</div>
                                    </div>
                                </div>
                            </>}

                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className="overflow-y-scroll h-[calc(100vh-190px)] md:h-[calc(100vh-190px)]">
                        {sentContacts.length > 0 ?
                            sentContacts.map((element, index) => {
                                try {
                                    if (element.loadMore)
                                        return (
                                            <div
                                                onClick={(e) => props.loadMoreContacts(e, 'sent')}
                                            >
                                                <div
                                                    key={index}
                                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2 hover:bg-gray-200 cursor-pointer"
                                                >
                                                    <div className="w-full text-center pl-2">
                                                        <div className="font-semibold">Cargar más</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    const contact = element;
                                    const lastMessage = element.lastMessage ? element.lastMessage : { text: { body: "" }, timestamp: "" };
                                    if (contact)
                                        return (
                                            <div
                                                onClick={() => {
                                                    loadContactMessages(index, 'sent');
                                                    element.selected = true;
                                                    setLoading(true);
                                                }}
                                            >
                                                <Contact
                                                    key={index}
                                                    name={
                                                        contact.NombreCompleto
                                                    }
                                                    draft={element.draft ?? ""}
                                                    lastMessage={
                                                        lastMessage.text ? ((typeof lastMessage.text.body === 'string') ? lastMessage.text.body : lastMessage.text.body.name) : lastMessage.type
                                                    }
                                                    lastMessageTimestamp={
                                                        lastMessage.timestamp ? lastMessage.timestamp : ""
                                                    }
                                                    newMessages={contact.newMessages}
                                                />
                                            </div>
                                        );
                                    return <></>;
                                } catch (error) {
                                    return <></>;
                                }
                            }) : <>
                                <div
                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2"
                                >
                                    <div className="w-full text-left pl-2">
                                        <div className="font-semibold">No tiene conversaciones asignadas</div>
                                    </div>
                                </div>
                            </>}

                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div className="overflow-y-scroll h-[calc(100vh-190px)] md:h-[calc(100vh-190px)]">
                        {unreadContacts.length > 0 ?
                            unreadContacts.map((element, index) => {
                                try {
                                    if (element.loadMore)
                                        return (
                                            <div
                                                onClick={(e) => props.loadMoreContacts(e, 'unread')}
                                            >
                                                <div
                                                    key={index}
                                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2 hover:bg-gray-200 cursor-pointer"
                                                >
                                                    <div className="w-full text-center pl-2">
                                                        <div className="font-semibold">Cargar más</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    const contact = element;
                                    const lastMessage = element.lastMessage;
                                    if (contact)
                                        return (
                                            <div
                                                onClick={() => {
                                                    loadContactMessages(index, 'unread');
                                                    element.selected = true;
                                                    setLoading(true);
                                                }}
                                            >
                                                <Contact
                                                    key={index}
                                                    name={
                                                        contact.NombreCompleto
                                                    }
                                                    draft={contact.draft ? contact.draft : ""}
                                                    lastMessage={
                                                        lastMessage.text ? ((typeof lastMessage.text.body === 'string') ? lastMessage.text.body : lastMessage.text.body.name) : lastMessage.type
                                                    }
                                                    lastMessageTimestamp={
                                                        lastMessage.timestamp ? lastMessage.timestamp : ""
                                                    }
                                                    newMessages={contact.newMessages}
                                                />
                                            </div>
                                        );
                                    return <></>;
                                } catch (error) {
                                    return <></>;
                                }
                            }) : <>
                                <div
                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2"
                                >
                                    <div className="w-full text-left pl-2">
                                        <div className="font-semibold">No tiene conversaciones asignadas</div>
                                    </div>
                                </div>
                            </>}

                    </div>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <div className="overflow-y-scroll h-[calc(100vh-190px)] md:h-[calc(100vh-190px)]">
                        {automatedContacts && automatedContacts.length > 0 ?
                            automatedContacts.map((element, index) => {
                                try {
                                    if (element.loadMore)
                                        return (
                                            <div
                                                onClick={(e) => props.loadMoreContacts(e, 'unread')}
                                            >
                                                <div
                                                    key={index}
                                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2 hover:bg-gray-200 cursor-pointer"
                                                >
                                                    <div className="w-full text-center pl-2">
                                                        <div className="font-semibold">Cargar más</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    const contact = element;
                                    const lastMessage = element.lastMessage;
                                    if (contact)
                                        return (
                                            <div
                                                onClick={() => {
                                                    loadContactMessages(index, 'unread');
                                                    element.selected = true;
                                                    setLoading(true);
                                                }}
                                            >
                                                <Contact
                                                    key={index}
                                                    name={
                                                        contact.NombreCompleto
                                                    }
                                                    draft={contact.draft ? contact.draft : ""}
                                                    lastMessage={
                                                        lastMessage.text ? ((typeof lastMessage.text.body === 'string') ? lastMessage.text.body : lastMessage.text.body.name) : lastMessage.type
                                                    }
                                                    lastMessageTimestamp={
                                                        lastMessage.timestamp ? lastMessage.timestamp : ""
                                                    }
                                                    newMessages={contact.newMessages}
                                                />
                                            </div>
                                        );
                                    return <></>;
                                } catch (error) {
                                    return <></>;
                                }
                            }) : <>
                                <div
                                    className="flex flex-row py-4 px-2 justify-center items-center border-b-2"
                                >
                                    <div className="w-full text-left pl-2">
                                        <div className="font-semibold">No tiene conversaciones asignadas</div>
                                    </div>
                                </div>
                            </>}

                    </div>
                </TabPanel>
            </Box>
        </div>
    )
}