import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../services/auth.service";
import ResponsiveAppBar from "./ResponsiveAppBar";

export const ProtectedAdminLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  } else if (!user.roles.includes("ROLE_ADMIN")) {
    return <Navigate to="/" />;
  }
  return (
    <div>
      <ResponsiveAppBar />
      {outlet}
    </div>
  );
};
