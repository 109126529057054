import React from 'react';
import { useAuth } from "../services/auth.service";

function SurveyForm() {
    const { user } = useAuth();
    const [value, setValue] = React.useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        //create an exios post request to the backend
        fetch(process.env.REACT_APP_BACKEND_API + "/surveys", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'x-access-token': user.accessToken,
            },
            body: value,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (data.error) {
                        alert(data.error);
                    } else {
                        alert(data._id);
                    }
                }
            });
    }

    return (
        <div className="rounded-lg border bg-card text-card-foreground shadow-sm" data-v0-t="card">
            <div className="p-6">
                <div className="grid gap-4">
                    <label className="font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-lg" htmlFor="json">
                        Copie y pegue su codigo JSON de Whatsapp Flow API aqui
                    </label>
                    <textarea
                        onChange={(e) => setValue(e.target.value)}
                        className="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 min-h-[200px]"
                        id="json"
                        placeholder="Paste your JSON here."
                    />
                </div>
            </div>
            <div className="flex items-center p-6">
                <button onClick={(e) => handleSubmit(e)} className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-11 rounded-md px-8">
                    Create
                </button>
            </div>
        </div>
    );
}

export default SurveyForm;