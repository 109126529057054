import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export function DatePickerCustom(props) {
  const [value, setValue] = React.useState(dayjs(props.value));
  const handleChange = (newValue) => {
    setValue(newValue);
    props.onChange(newValue)
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={'Mes y Año'}
        views={['month', 'year']}
        value={value}
        onChange={(newValue) => handleChange(newValue)}
        minDate={dayjs('2015-11-11')}
        maxDate={dayjs(value).add(1, 'year')}
      />
    </LocalizationProvider>
  );
}

export default DatePickerCustom;