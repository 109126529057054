import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useAuth } from "../services/auth.service";
import { EjectOutlined } from "@mui/icons-material";

export default function NewModelModal(props) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectItem, setSelectItem] = useState(null);
  const { user, logout, currentConfig, changeCurrentConfig, getCurrentConfig } = useAuth();
  const [newdatasource, setNewDatasource] = useState(null);
  const [formItems, setFormItems] = useState([]);

  useEffect(() => {
    //check if props.body contains and item with type select
    let countselectdatasources = 0;
    props.body.forEach((item) => {
      if (item.type === "select-datasource") {
        setNewDatasource(item);
        setSelectItem({ type: item.type, dataSource: item.dataSource });
        countselectdatasources++;
      }
    });
    if (countselectdatasources === 0) {
      setSelectItem({ type: "select", dataSource: null });
    }
    setFormItems(props.body);
  }, [props.body]);

  const selectShow = (e, action, items) => {
    props.onChange(e, action);
    document.getElementById(e.value).classList.remove("hidden");
    let inputclasses = "form-control block w-full px-3 py-1.5 0text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none".split(' ');
    document.getElementById(e.value).classList.add(...inputclasses);
    items.forEach((item) => {
      if (item !== e.value) {
        document.getElementById(item).classList.add("hidden");
        document.getElementById(item).value = "";
      }
    }
    );
  }

  const selectSource = (e, action, items) => {
    const value = e.value;
    let newFormItems = formItems.map((selecteditem, index) => {

      if (selecteditem.type !== "select") return selecteditem;
      if (!newdatasource.nextSourceIds.includes(selecteditem.id)) {
        return selecteditem;
      }
      else {
        selecteditem.dataSource = value;
        return selecteditem;
      }

    });
    setFormItems(newFormItems);
    props.onChange(e, action);
  }

  useEffect(() => {
    //fetch data from selectItem.url
    if (selectItem) {
      async function fetchData() {
        let allOptions = [];
        await Promise.all(formItems.map(async (selecteditem, index) => {
          if (selecteditem.type === "select-add")
            allOptions.push({ id: index, options: [] });
          if (selecteditem.type !== "select" && selecteditem.type !== "select-datasource")
            return;
          let response = await fetch(process.env.REACT_APP_BACKEND_API + selecteditem.dataSource, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": user.accessToken,
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error("Something went wrong ...");
              }
            })
            .then((data) => {
              let options = data.map((item) => {
                return { value: item[selecteditem.displayColumn ? selecteditem.displayColumn : "_id"], label: item[selecteditem.displayColumn ? selecteditem.displayColumn : "_id"], disabled: item.ejecutivos > 0 ? false : true };
              });
              let newSelectOptions = { id: index, options: options };
              return newSelectOptions;
            })
            .catch((error) => {
              return null;
            });
          allOptions.push(response);
        }));
        setSelectOptions(allOptions);
      }
      fetchData();
    }

  }, [selectItem, formItems]);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster backdrop-blur-sm bg-black/30" style={{ display: 'none' }}>
      <div className="border border-gray-200 rounded-lg shadow-lg modal-container bg-white w-11/12 md:w-fit mx-auto rounded shadow-lg z-50 overflow-y-auto h-5/6">
        <div className="modal-content py-4 text-left px-6">
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-bold">{props.title}</p>
            <div
              className="modal-close cursor-pointer z-50"
              onClick={props.onClose}
            >
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          <div className="my-5">
            <div class="block p-6 rounded-lg shadow-lg bg-white max-w-md">
              <form
                action={props.action}
                onSubmit={props.onSubmit}
                method={props.method}
              >
                <div class="grid ">
                  {formItems.map((item, key) => {
                    if (
                      item.type === "text" ||
                      item.type === "email" ||
                      item.type === "password" ||
                      item.type === "number"
                    )
                      return (
                        <div class="form-group m-3">
                          <input
                            type={item.type}
                            onChange={props.onChange}
                            value={item.value}
                            class="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            id={item.id}
                            key={key}
                            name={item.name}
                            aria-describedby="emailHelp123"
                            placeholder={item.placeholder}
                          />
                        </div>
                      );
                    else if (item.type === "hidden")
                      return (
                        <div class="form-group m-3">
                          <input
                            id={item.id}
                            key={key}
                            placeholder={item.placeholder ? item.placeholder : ""}
                            name={item.name}
                            type="text"
                            value={item.value}
                            class="hidden"
                            checked={item.value}
                            onChange={props.onChange}
                          />
                        </div>
                      );
                    else if (item.type === "checkbox")
                      return (
                        <div class="form-group m-3">
                          <input type="checkbox" id={item.id} key={key} name={item.name} onChange={props.onChange} checked={item.value == "on" ? true : false} />
                          <label for={item.id} class="ml-2">{item.placeholder}</label>
                        </div>
                      );
                    else if (item.type === "textarea")
                      return (
                        <div class="form-group m-3">
                          <textarea
                            onChange={props.onChange}
                            class="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            id={item.id}
                            key={key}
                            name={item.name}
                            aria-describedby="emailHelp123"
                            placeholder={item.placeholder}
                            rows={5}
                            cols={5}
                            value={item.value}
                          />
                        </div>
                      );
                    else if (item.type === "select")
                      return (
                        <div class="form-group m-3">
                          <Select
                            isMulti={item.multiselect}
                            placeholder={item.placeholder}
                            value={item.value}
                            name={item.name}
                            onChange={props.onChange}
                            isLoading={selectOptions ? false : true}
                            options={selectOptions ? selectOptions.find((selectitem) => selectitem.id === key)?.options : options}
                            isOptionDisabled={() => item.value.length > item.limit}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      );
                    else if (item.type === "select-add")
                      return (
                        <div class="form-group m-3">
                          <Select
                            isMulti={item.multiselect}
                            placeholder={item.placeholder}
                            value={item.value}
                            name={item.name}
                            onChange={props.onChange}
                            onKeyDown={(e) => {

                              if (e.key === "Enter") {
                                e.preventDefault();
                                let newOption = { value: e.target.value, label: e.target.value };
                                let newOptions = selectOptions.find((selectitem) => selectitem.id === key)?.options;
                                if (!newOptions) newOptions = [];
                                newOptions.push(newOption);
                                let newSelectOptions = selectOptions.map((selectitem) => {
                                  if (selectitem.id === key) {
                                    selectitem.options = newOptions;
                                  }
                                  return selectitem;
                                }
                                );
                                setSelectOptions(newSelectOptions);
                              }
                            }}
                            isLoading={selectOptions ? false : true}
                            options={selectOptions ? selectOptions.find((selectitem) => selectitem.id === key)?.options : options}
                            isOptionDisabled={() => item.value.length > item.limit}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      );
                    else if (item.type === "select-fixed")
                      return (
                        <div class="form-group m-3">
                          <Select
                            isMulti={item.multiselect}
                            placeholder={item.placeholder}
                            value={item.value}
                            name={item.name}
                            onChange={props.onChange}
                            isLoading={selectOptions ? false : true}
                            options={item.options.map((option) => { return { value: option, label: option } })}
                            isOptionDisabled={() => item.value.length > item.limit}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      );
                    else if (item.type === "select-show")
                      return (
                        <div class="form-group m-3">
                          <Select
                            placeholder={item.placeholder}
                            value={item.value}
                            name={item.name}
                            onChange={(e, action) => selectShow(e, action, item.optionIds)}
                            options={item.optionIds.map((optionId) => { return { value: optionId, label: optionId } })}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      )
                    else if (item.type === "select-datasource")
                      return (
                        <div class="form-group m-3">
                          <Select
                            placeholder={item.placeholder}
                            value={item.value}
                            name={item.name}
                            onChange={(e, action) => selectSource(e, action, item.nextSourceIds)}
                            options={selectOptions ? selectOptions.find((selectitem) => selectitem.id === key)?.options : options}
                            defaultValue={item.defaultValue}
                          />
                        </div>
                      )
                  })}
                </div>
                <button
                  type="submit"
                  class="
                    mt-6
                    w-full
                    px-6
                    py-2.5
                    bg-blue-600
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out"
                >
                  {props.buttonText}
                </button>
              </form>
              <p>{props.modalResult}</p>
            </div>
          </div>
          <div className="flex justify-end pt-2">
            <button
              onClick={props.onClose}
              className={
                "focus:outline-none modal-close px-4 p-3 rounded-lg text-white" +
                (props.disabled
                  ? " bg-gray-100 hover:bg-gray-200"
                  : " bg-gray-400 hover:bg-gray-300")
              }
              disabled={props.disabled}
            >
              Exit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
