import React, { useEffect } from 'react';

export default function MessagesSkeleton(props) {
    const [search, setSearch] = React.useState('');

    useEffect(() => {
        setSearch(props.search);
    }, [props.search]);

    return(
        <div className="border-b-2 py-4 px-2">
          <input
            type="text"
            value={search}
            onChange={props.onChange}
            placeholder="Buscar contactos"
            className="py-2 px-2 border-2 border-gray-200 rounded-2xl w-full"
          />
        </div>
    )
}