import { loadContacts, loadMessages, findContact } from "../services/wapib.service";

/**
 * Save the messages on the localstorage
 * @param message the message to save
 * @param to the contact to save the message
 */
const saveSentMessage = (newmessage, text) => {
  try {
    const { contacts, messages } = newmessage.data;
    let messageStorage = localStorage.getItem("messages/" + contacts[0].wa_id);
    let newMessage = {
      entry: [
        {
          changes: [
            {
              field: "messages",
              value: {
                contacts: [
                  {
                    profile: {
                      name: process.env.REACT_APP_PHONE_NAME,
                    },
                    wa_id: process.env.REACT_APP_PHONE_NUMBER,
                  },
                ],
                messages: [
                  {
                    from: process.env.REACT_APP_PHONE_NUMBER,
                    id: messages[0].id,
                    text: {
                      body: text,
                    },
                    timestamp: parseInt(new Date().getTime() / 1000) + "",
                    type: "text",
                  },
                ],
                messaging_product: "whatsapp",
                metadata: {
                  display_phone_number: process.env.REACT_APP_PHONE_NUMBER,
                  phone_number_id: process.env.REACT_APP_PHONE_ID,
                },
              },
            },
          ],
          id: "105520548863497",
        },
      ],
      object: "whatsapp_business_account",
    };
    if (messageStorage) {
      let messagesToStore = JSON.parse(messageStorage);
      messagesToStore.push(newMessage);
      localStorage.setItem(
        "messages/" + contacts[0].wa_id,
        JSON.stringify(messagesToStore)
      );
    } else {
      localStorage.setItem(
        "messages/" + contacts[0].wa_id,
        JSON.stringify([newMessage])
      );
    }
    return newMessage;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const saveContacts = (contacts, type) => {
  //get user from localstorage
  let user = JSON.parse(localStorage.getItem('user'));
  if (!type) {
    localStorage.setItem(`contacts/${user.id}`, JSON.stringify(contacts));
  }
  else if (type === 'sent') {
    localStorage.setItem(`contacts/sent${user.id}`, JSON.stringify(contacts));
  }
  else if (type === 'unread') {
    localStorage.setItem(`contacts/unread${user.id}`, JSON.stringify(contacts));
  }
};

const saveOnStorage = (messagesDB) => {
  localStorage.setItem("messages/lastquery", JSON.stringify(new Date()));
  messagesDB.forEach((messageDB) => {
    const { contacts, messages } =
      messageDB.entry[0].changes[0].value;
    let messageStorage = localStorage.getItem("messages/" + contacts[0].wa_id);
    if (messageStorage) {
      messageStorage = JSON.parse(messageStorage);
      //check if the message id exists
      let messageExists = messageStorage.find(
        (message) =>
          message.entry[0].changes[0].value.messages[0].id === messages[0].id
      );
      if (!messageExists) {
        messageStorage.push(messageDB);
        localStorage.setItem(
          "messages/" + contacts[0].wa_id,
          JSON.stringify(messageStorage)
        );
      }
    } else {
      localStorage.setItem(
        "messages/" + contacts[0].wa_id,
        JSON.stringify([messageDB])
      );
    }
    /*let contactStorage = localStorage.getItem('contacts/');
        //insert the contact on the localstorage if not exists
        if(contactStorage){
            contactStorage = JSON.parse(contactStorage);
            let contact = contactStorage.find((contact)=>contact.wa_id == contacts[0].wa_id);
            if(!contact){
                //append the last message to the contact
                contacts[0].lastMessage = messages[0].text.body;
                contactStorage.push(contacts[0]);
                localStorage.setItem('contacts/', JSON.stringify(contactStorage));
            }
        }else{
            //append the last message to the contact
            contacts[0].lastMessage = messages[0].text.body;
            localStorage.setItem('contacts/', JSON.stringify([contacts[0]]));
        }*/
  });
};

const checkStorage = () => {
  return localStorage.getItem("messages/lastquery") ? true : false;
};

const getMessages = async (wa_id, start, token) => {
  //retrieve in timestamp order
  //let messages = JSON.parse(localStorage.getItem('messages/'+ wa_id));
  //if(!messages){
  let messages = await loadMessages(wa_id, start, token);
  //}
  messages.sort((a, b) => {
    return (
      a.entry[0].changes[0].value.messages[0].timestamp -
      b.entry[0].changes[0].value.messages[0].timestamp
    );
  });
  return messages;
};

const searchContact = async (token, search) => {
  let contacts = await findContact(token, search);
  return contacts;
}

const getContacts = async (token, start, type) => {
  //get user from localstorage
  let user = JSON.parse(localStorage.getItem('user'));
  //let contacts = JSON.parse(localStorage.getItem('contacts/'));
  //if(!contacts){
  let newContacts = await loadContacts(token, start, type);
  if (newContacts.length >= 20) {
    newContacts.push(
      {
        loadMore: true
      }
    );
  }
  if (start) {
    let contacts = [];
    if (type === 'sent') {
      try {
        contacts = JSON.parse(localStorage.getItem(`contacts/sent${user.id}`));
        //remove the items with sentMessage != true
        contacts = contacts.filter((contact) => contact.sentMessage === true);
      } catch (error) {
        contacts = [];
      }
    }
    else if (type === 'unread') {
      try {
        contacts = JSON.parse(localStorage.getItem(`contacts/unread${user.id}`));
        //remove the items with newMessages = 0
        contacts = contacts.filter((contact) => contact.newMessages > 0);
      } catch (error) {
        contacts = [];
      }
    }
    else {
      contacts = JSON.parse(localStorage.getItem(`contacts/${user.id}`));
      //contacts = [];
    }
    if (contacts) {
      contacts.pop();
    } else {
      contacts = [];
    }
    newContacts = contacts.concat(newContacts);
    //remove duplicates by _id
    newContacts = newContacts.filter((contact, index, self) =>
      index === self.findIndex((t) => (
        t._id === contact._id
      ))
    )

  }
  //if there more than 20 items add a new fake one
  saveContacts(newContacts, type);
  return newContacts;
  //}
  //return JSON.parse(localStorage.getItem('contacts/'));
};

const updateContact = (wa_id, lastMessage, draft) => {
  //get user from localstorage
  let user = JSON.parse(localStorage.getItem('user'));
  let contacts = JSON.parse(localStorage.getItem(`contacts/${user.id}`));
  let contact = contacts.find((contact) => contact.wa_id === wa_id);
  contact.lastMessage = lastMessage ? lastMessage : contact.lastMessage;
  contact.draft = draft ? draft : contact.draft;
  localStorage.setItem(`contacts/`, JSON.stringify(contacts));
};

export {
  saveOnStorage,
  checkStorage,
  getMessages,
  getContacts,
  updateContact,
  saveSentMessage,
  searchContact
};
