import React, { useState, useEffect } from "react";
import NewModelModal from "./Modal";

export default function TemplateCobro() {
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const [modalResult, setModalResult] = useState("");
  const [templates, setTemplates] = useState([]);
  const [currentFormAction, setCurrentFormAction] = useState("/");
  const [currentFormMethod, setCurrentFormMethod] = useState("POST");
  const [currentButtonText, setCurrentButtonText] = useState("Guardar");
  const [modalBody, setModalBody] = useState([]);
  const defaultModalBody = [
    {
      id: "name",
      name: "name",
      type: "text",
      placeholder: "Nombre_Plantilla",
      value: "",
    },
    {
      id: "parameters",
      name: "parameters",
      type: "select",
      dataSource: "/cobroscolumns",
      multiselect: true,
      value: "",
      defaultValue: [],
      limit: 7,
	  placeholder: "Parametros",
    },
    {
      id: "image",
      name: "image",
      type: "text",
      placeholder: "https://ejemplo.com/imagen.png",
      value: "",
    },
    {
      id: "buttonparameters",
      name: "buttonparameters",
      type: "select",
      dataSource: "/cobroscolumns",
      multiselect: true,
      value: "",
      defaultValue: [],
      limit: 2,
	    placeholder: "Parametros_Boton",
    },
    {
      id: "buttonparameterindex",
      name: "buttonparameterindex",
      type: "number",
      placeholder: "1 o 0",
      value: "0",
    },
    {
        id: "type",
        name: "type",
        type: "hidden",
        placeholder: "Tipo",
        value: "Cobros",
    }
  ];

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_API + "/template?type=Cobros")
      .then((res) => res.json())
      .then((data) => {
        setTemplates(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  
  const formOnChange = (e,action) => {
    try {
      const { name, value } = e.target;
      setModalBody(
        modalBody.map((item) => {
          if (item.name === name) {
            item.value = value;
          }
          return item;
        })
      );
    } catch (error) {
      const items = e;
      setModalBody(
        modalBody.map((bodyItem) => {
          if (
            bodyItem.type === "select" &&
            bodyItem.name === action.name
          ) {
			bodyItem.value = items;
            return bodyItem;
          }
          return bodyItem;
        })
      );
    }
  };

  const newTemplate = (e) => {
    e.preventDefault();
    setModalBody(defaultModalBody);
    setCurrentFormAction("/");
    setCurrentFormMethod("POST");
    setCurrentButtonText("Guardar");
    openModal();
  };

  const deleteTemplate = (e) => {
    e.preventDefault();
    const id = templates[e.currentTarget.dataset.id]._id;
    fetch(process.env.REACT_APP_BACKEND_API + "/template/" + id, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTemplates(templates.filter((template) => template._id !== id));
      });
  };

  const editTemplate = (e) => {
    e.preventDefault();
    const id = templates[e.currentTarget.dataset.id]._id;
    setCurrentFormAction("/" + id);
    setCurrentFormMethod("PUT");
    setCurrentButtonText("Actualizar");
    setModalBody(
      //avoid the field type password
      defaultModalBody
        .filter((item) => item.name !== "password")
        .map((item) => {
          item.value = templates[e.currentTarget.dataset.id][item.name]
            ? templates[e.currentTarget.dataset.id][item.name]
            : "";
          //check if the property item.defaultValue exists
          if (item.defaultValue) {
            item.defaultValue = templates[e.currentTarget.dataset.id][item.name]
              ? templates[e.currentTarget.dataset.id][item.name].map(
                  (optionItem) => {
                    return {
                      value: optionItem,
                      label: optionItem,
                      disabled: false,
                    };
                  }
                )
              : [];
          }
          return item;
        })
    );
    openModal();
  };

  const cleanModalForm = () => {
    setModalBody(
      modalBody.map((item) => {
        item.value = "";
        return item;
      })
    );
  };

  const submitTemplate = (e) => {
    e.preventDefault();
    setModalSubmitting(true);
    /*const form = e.target;
    const data = new FormData(form);
    const value = Object.fromEntries(data.entries());
    console.log(value);*/
    const value = modalBody.reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});
    //send the data post to endpoint /template
    fetch(process.env.REACT_APP_BACKEND_API + "/template" + currentFormAction, {
      method: currentFormMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (currentFormMethod == "POST") {
          setTemplates([...templates, data]);
          setModalResult("Template creado correctamente");
        } else if (currentFormMethod == "PUT") {
          setTemplates(
            templates.map((template) => {
              if (template._id === data._id) {
                //only update the fields that are not empty
                for (const key in data) {
                  if (data[key] !== "") {
                    template[key] = data[key];
                  }
                }
                return template;
              }
              return template;
            })
          );
          setModalResult("Ejecutivo actualizado correctamente");
        }
        //disvanish the modal result at 3 seconds
        setTimeout(() => {
          setModalResult("");
        }, 3000);
        cleanModalForm();
        setModalSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setModalResult(err.message);
        setModalSubmitting(false);
      });
  };
  const openModal = () => {
    const modal = document.querySelector(".main-modal");
    modal.classList.remove("fadeOut");
    modal.classList.add("fadeIn");
    modal.style.display = "flex";
  };

  const closeModal = () => {
    const modal = document.querySelector(".main-modal");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");
    modal.style.display = "none";
    setModalBody(defaultModalBody);
  };

  return (
    <div>
      <div className="bg-white p-8 rounded-md w-full">
        <div className="md:flex items-center justify-between pb-6">
          <div>
            <h2 className="text-gray-600 font-semibold">Templates</h2>
            <span className="text-xs">
              Seleccione y edite plantillas de Whatsapp Business
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex bg-gray-50 items-center p-2 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
              <input
                className="bg-gray-50 outline-none ml-1 block "
                type="text"
                name=""
                id=""
                placeholder="Buscar..."
              />
            </div>
            <div className="lg:ml-40 ml-10 space-x-8">
              <button
                onClick={newTemplate}
                className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
              >
                Nueva Plantilla
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Nombre
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Body
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Created at
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Image Header
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Buttons
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase">
                      Status
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase">
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map((template, key) => {
                    return (
                      <tr>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {template.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {template.parameters
                              ? template.parameters.map((parameter, key) => {
                                  return parameter.value + " ";
                                })
                              : "No tiene parametros asignados"}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {template.created_at}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {template.image}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            ></span>
                            <span className="relative">
                              {template.button_parameters
                                ? template.button_parameters.map(
                                    (parameter, key) => {
                                      return parameter.value + " ";
                                    }
                                  )
                                : "No tiene parametros asignados"}
                            </span>
                          </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {template.status}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                          <div
                            className="inline-flex shadow-md hover:shadow-lg focus:shadow-lg"
                            role="group"
                          >
                            <button
                              onClick={editTemplate}
                              data-id={key}
                              type="button"
                              className="rounded-l inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out"
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              onClick={deleteTemplate}
                              data-id={key}
                              className="rounded-r inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase hover:bg-red-700 focus:bg-red-700 focus:outline-none focus:ring-0 active:bg-red-800 transition duration-150 ease-in-out"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewModelModal
        title={`Crear Template`}
        onClose={closeModal}
        body={modalBody}
        onSubmit={submitTemplate}
        modalResult={modalResult}
        onChange={formOnChange}
        method={currentFormMethod}
        action={currentFormAction}
        buttonText={currentButtonText}
      />
    </div>
  );
}
