import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import { useAuth } from "../services/auth.service";
import axios from "axios";
import { DeleteOutlineRounded } from '@mui/icons-material';

const options = [
    {
        value: 'template',
        label: 'Plantillas de mensaje',
    },
    {
        value: 'raw',
        label: 'Mensaje con cuerpo personalizado',
    },
    {
        value: 'list',
        label: 'Mensaje de Lista',
    },
    {
        value: 'quick',
        label: 'Plantillas de respuestas rapidas',
    },
];

function Select({ value, handleId, nodeId }) {
    const { user, logout } = useAuth();
    const { setNodes, setEdges } = useReactFlow();
    const store = useStoreApi();
    const [templates, setTemplates] = React.useState([]);

    React.useEffect(() => {
        const getTemplates = async () => {
            const url = process.env.REACT_APP_BACKEND_API + "/template";
            const config = {
                headers: {
                    "x-access-token": user.accessToken,
                    "Content-Type": "application/json",
                },
            };
            let result = await axios
                .get(url, config)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
            if (result.data)
                setTemplates(result.data);
        };
        getTemplates();
    }, []);

    let id = parseInt(nodeId) + 1;
    const getId = () => `${id++}`;

    const onChange = (evt) => {
        const { nodeInternals } = store.getState();

        //get the selected node from nodeId
        const nodeSelectIs = Array.from(nodeInternals.values()).filter((node) => {
            if (node.id === nodeId) {
                return node;
            }
        }
        )[0];
        const id = getId();
        const newNode = {
            id,
            type: 'output',
            style: {
                background: '#63B3ED',
                color: 'white',
                width: 100,
            },
            data: {
                label: 'Seleccione una plantilla de mensajes',
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            position: {
                x: nodeSelectIs.position.x + 500,
                y: nodeSelectIs.position.y,
            },
            origin: [0.5, 0.0],
        };
        setNodes((nds) => nds.concat(newNode));
        //append the new node to the selected node
        setEdges((eds) => eds.concat({ id: getId(), source: nodeId, sourceHandle: handleId, target: id }));
    };

    return (
        <div className="custom-node__select">
            <div>Tipo de Mensaje</div>
            <Handle type="target" position={Position.Left} id={handleId} />
            <select className="nodrag" onChange={onChange} value={value}>
                {templates.map((option, key) => (
                    <option key={key} value={option._id}>
                        {option.name}
                    </option>
                ))}
            </select>
            <Handle type="source" position={Position.Right} id={handleId} />
        </div>
    );
}

function SelectTemplateNode({ id, data }) {
    const store = useStoreApi();
    const { setNodes } = useReactFlow();
    const deleteNode = (idToDelete) => {
        const { nodeInternals } = store.getState();
        //delete the node
        const newNodeInternals = Array.from(nodeInternals.values()).filter((node) => {
            if (node.id !== idToDelete) {
                return node;
            }
        });
        //update the nodes
        setNodes((nds) => [...newNodeInternals]);
    }
    return (
        <>
            <div className="custom-node__header">
                Seleccione un tipo de mensaje o plantilla
                <span
                    onClick={() => {
                        deleteNode(id);
                    }}
                    className='absolute right-1 top-1 cursor-pointer'>x</span>
            </div>
            <div className="custom-node__body">
                {Object.keys(data.selects).map((handleId) => (
                    <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
                ))}
            </div>
        </>
    );
}

export default memo(SelectTemplateNode);
