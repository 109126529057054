import React from "react";

export default function MediaMessage(props) {
  const mediaType = () => {
    if (props.media.type === "image") {
      return (
        <>
          <img
            src={
              !props.isSender ? (process.env.REACT_APP_BACKEND_API_MEDIA +
                "/" +
                props.media.image.id +
                "." +
                props.media.image.mime_type.split('/')[1]) :
                (props.media.text.url)
            }
            alt={!props.isSender ? props.media.image.id : props.media.text.url}
            height={450}
            width={450}
          />
          <span>{!props.isSender ? props.media.image.caption : props.media.text.caption}</span>
        </>
      );
    }
    else if (props.media.type === "sticker") {
      return (
        <>
          <img
            src={
              !props.isSender ? (
                process.env.REACT_APP_BACKEND_API_MEDIA +
                "/" +
                props.media.sticker.id +
                "." +
                props.media.sticker.mime_type.split('/')[1]) :
                (props.media.text.url)
            }
            alt={!props.isSender ? (props.media.sticker.id) : (props.media.text.caption)}
            height={100}
            width={100}
          />
        </>
      );
    }
    else if (props.media.type === "video") {
      return (
        <>
          <video controls width={450} height={450}>
            <source
              src={
                !props.isSender ? (
                  process.env.REACT_APP_BACKEND_API_MEDIA +
                  "/" +
                  props.media.video.id +
                  "." +
                  props.media.video.mime_type.split('/')[1]) :
                  (props.media.text.url)
              }
              type="video/mp4"
            />
          </video>
          <span>{!props.isSender ? (props.media.video.caption) : (props.media.text.caption)}</span>
        </>
      );
    } else if (props.media.type === "audio") {
      return (
        <audio controls>
          <source
            src={
              !props.isSender ? (
                process.env.REACT_APP_BACKEND_API_MEDIA +
                "/" +
                props.media.audio.id +
                "." +
                props.media.audio.mime_type.split('/')[1]) :
                (props.media.text.url)
            }
            type="audio/ogg"
          />
        </audio>
      );
    } else if (props.media.type === "document" || props.media.type === "file") {
      return (
        <>
          <a
            href={
              !props.isSender ? (
                process.env.REACT_APP_BACKEND_API_MEDIA +
                "/" +
                props.media.document.id +
                "." +
                props.media.document.filename.split('.').pop().toLowerCase()) :
                (props.media.text.url)
            }
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline cursor"
          >
            <div className="bg-blue-200 p-4 rounded-2xl flex">
              <div className="mr-2">

                {!props.isSender ?
                  <img src={process.env.PUBLIC_URL + `/${props.media.document.filename.split('.').pop().toLowerCase()}32.png`} alt="pdf" className="w-6 h-6" />
                  :
                  <img src={process.env.PUBLIC_URL + `/${props.media.text.url.split('.').pop().toLowerCase()}32.png`} alt="pdf" className="w-6 h-6" />
                }
              </div>

              {!props.isSender ? props.media.document.filename : props.media.text.caption} {props.isSender ? props.media.text.url.split('.').pop().toUpperCase():""}
            </div>

          </a>
        </>
      );
    }
  };

  return mediaType();
}
