import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts'
import { useAuth } from "../services/auth.service";
import { plPL } from '@mui/x-date-pickers';

export default function Survey() {
    const { user } = useAuth();
    const [charts, setCharts] = React.useState([]);
    const [textResponses, setTextResponses] = React.useState(null);

    const [counts, setCounts] = React.useState([])

    const [surveyTime, setSurveyTime] = React.useState(0);

    const [questionAnswers, setQuestionAnswers] = React.useState([]);

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {
            if (isMounted) {
                // Your async operations
                const countBy = [{ value: 'thisYear', name: 'Este año' }, { value: 'thisMonth', name: 'Este mes' }, { value: 'thisWeek', name: 'Esta semana' }, { value: 'today', name: 'Hoy' }, { value: 'hour', name: 'Ultima hora' }]

                countBy.forEach((period) => {

                    fetch(`${process.env.REACT_APP_BACKEND_API}/surveycount?${period.value}=1`, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            'x-access-token': user.accessToken,
                        }
                    })
                        .then((response) => response.json())
                        .then((responseData) => {
                            if (responseData) {
                                setCounts((prevCounts) => [...prevCounts, { period: period.name, count: responseData.count }]);
                            }
                        });
                });

                fetch(process.env.REACT_APP_BACKEND_API + "/surveys", {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        'x-access-token': user.accessToken,
                    }
                })
                    .then((response) => response.json())
                    .then((responseData) => {
                        if (responseData) {

                            const newCharts = Object.entries(responseData).map(([question, answerData], index) => {
                                if (answerData.type === 'Selection') {
                                    const series = answerData.sources.map(source => {
                                        const count = answerData.answers.filter(answer => answer === source.id).length;
                                        return { x: source.title, y: count };
                                    });
                                    //make a bar chart options
                                    const options = {
                                        series: [{
                                            name: question,
                                            color: '#556ee6',
                                            data: series,
                                        }],
                                        chart: {
                                            type: 'bar',
                                            id: `chart-${index}`,
                                            height: '350px',
                                            width: '600px',
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: false,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            }
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        xaxis: {
                                            categories: answerData.sources.map(source => source.title),
                                        },
                                        yaxis: {
                                            stepSize: 1,
                                            title: {
                                                text: 'Respuestas',
                                            },
                                        },
                                        tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                    return val + " respuestas";
                                                }
                                            }
                                        },
                                        title: {
                                            text: question,
                                            align: 'left',
                                            style: {
                                                fontSize: '12px',
                                                color: '#666'
                                            }
                                        },
                                    };
                                    return options;
                                } else {
                                    return null;
                                }
                            }).filter((chart) => chart !== null);
                            //remove undefined index from array

                            setCharts(newCharts);
                            const newAnswers = Object.entries(responseData).map(([question, answerData], index) => {
                                return { question, answers: answerData.answers.filter((answer) => answer !== 'Sin respuesta'), contacts: answerData.contacts, numbers: answerData.numbers };
                            }).filter((answ) => answ !== null);
                            // const selectAnswers = Object.entries(responseData).map(([question, answerData], index) => {
                            //     if (answerData.type === 'Selection') {
                            //         return { question, answers: answerData.answers, contacts: answerData.contacts, numbers: answerData.numbers };
                            //     } else {
                            //         return null;
                            //     }
                            // }
                            // ).filter((answ) => answ !== null);
                            // setQuestionAnswers(selectAnswers);
                            setTextResponses(newAnswers.length > 0 ? newAnswers : null);
                        }
                    });
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        }
    }, []);


    const [isRendered, setIsRendered] = React.useState(false);

    useEffect(() => {
        if (!isRendered && charts.length > 0) {
            charts.forEach((chartItem) => {
                const chart = new ApexCharts(document.querySelector(`#${chartItem.chart.id}`), chartItem);
                chart.render();
            });
            setIsRendered(true);
        }
    }, [charts]);

    const displaySurveyData = (e) => {
        console.log(e.target)
    }


    return (
        <>
            <div className="flex flex-col w-full min-h-screen"><header className="flex items-center h-16 px-4 border-b shrink-0 md:px-6"><nav className="flex-col hidden gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6"><a className="flex items-center gap-2 text-lg font-semibold md:text-base" href="#" rel="ugc"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6"><path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" /><path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9" /><path d="M12 3v6" /></svg><span className="sr-only">
                Genesis Panama
            </span></a><a className="font-bold" href="#" rel="ugc">
                    Encuesta de Satisfacción
                </a><a className="text-gray-500 dark:text-gray-400" href="/admin/surveyform" rel="ugc">
                    Nueva encuesta
                </a></nav><div className="flex items-center w-full gap-4 md:ml-auto md:gap-2 lg:gap-4"><form className="flex-1 ml-auto sm:flex-initial"><div className="relative"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400"><circle cx={11} cy={11} r={8} /><path d="m21 21-4.3-4.3" /></svg><input type="search" className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]" placeholder="Search questionnaires..." /></div></form><button className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-full"><img alt="Avatar" className="rounded-full" height={32} src="/placeholder.svg" style={{ aspectRatio: '32/32', objectFit: 'cover' }} width={32} /><span className="sr-only">
                    Toggle user menu
                </span></button></div></header><main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 p-4 md:gap-8 md:p-10"><div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">

                    {
                        counts.map((count) => (
                            <div className="rounded-lg border bg-card text-card-foreground shadow-sm" data-v0-t="card">
                                <div className="p-6 flex flex-row items-center justify-between pb-2 space-y-0">
                                    <h3 className="whitespace-nowrap tracking-tight text-sm font-medium">
                                        Respuestas {count.period}
                                    </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 text-gray-500 dark:text-gray-400"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" /><circle cx={9} cy={7} r={4} /><path d="M22 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                </div>
                                <div className="p-6">
                                    <div className="text-2xl font-bold">
                                        {count.count}
                                    </div>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                        Respuestas en {count.period}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div><div className="grid gap-4 md:grid-cols-2"><div className="rounded-lg border bg-card text-card-foreground shadow-sm" data-v0-t="card"><div className="p-6 flex flex-row items-center justify-between pb-2 space-y-0"><h3 className="whitespace-nowrap tracking-tight text-sm font-medium">
                    Respuestas de la encuesta
                </h3><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 text-gray-500 dark:text-gray-400"><path d="M21.21 15.89A10 10 0 1 1 8 2.83" /><path d="M22 12A10 10 0 0 0 12 2v10z" /></svg></div>
                    <div className="p-6">
                        <div className="w-full aspect-[1/1]">
                            <div style={{ width: '100%', height: '100%' }}>
                                <div style={{ position: 'relative' }}>
                                    <div class="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">

                                        <div class="py-6 mt-4 grid place-items-center px-2">
                                            {charts.map((chart) => (
                                                <>
                                                    <div key={chart.chart.id} id={chart.chart.id} onClick={(e) => displaySurveyData(e)} />

                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        <div className="rounded-lg border bg-card text-card-foreground shadow-sm" data-v0-t="card">
                            <div className="relative w-full overflow-y-auto">
                                <table className="w-full caption-bottom text-sm">
                                    <thead className="[&_tr]:border-b">
                                        <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                                                Pregunta
                                            </th>
                                            <th className="h-12 px-4 align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 text-left">
                                                Respuesta
                                            </th>
                                            <th className="h-12 px-4 align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 text-left">
                                                Contacto
                                            </th>
                                            <th className="h-12 px-4 align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 text-left">
                                                Número
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="[&_tr:last-child]:border-0">
                                        {
                                            textResponses && textResponses.map((textResponse, index) => (
                                                textResponse.answers.map((answer, index) => (
                                                    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                                        <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0 font-medium">
                                                            {textResponse.question}
                                                        </td>
                                                        <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                                            {answer}
                                                        </td>
                                                        <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                                            {textResponse.contacts[index]}
                                                        </td>
                                                        <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                                                            {textResponse.numbers[index]}
                                                        </td>
                                                    </tr>))
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}