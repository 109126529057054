import React, { useState, useEffect } from "react";
import NewModelModal from "./Modal";
import { useAuth } from "../services/auth.service";
import axios from "axios";

export default function Template() {
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const [modalResult, setModalResult] = useState("");
  const [templates, setTemplates] = useState([]);
  const [tempTemplates, setTempTemplates] = useState([]);
  const [currentFormAction, setCurrentFormAction] = useState("/");
  const [currentFormMethod, setCurrentFormMethod] = useState("POST");
  const [currentButtonText, setCurrentButtonText] = useState("Guardar");
  const [currentTitle, setCurrentTitle] = useState("Nueva Plantilla");
  const [modalBody, setModalBody] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, logout, currentConfig, changeCurrentConfig, getCurrentConfig } = useAuth();
  const [searchValue, setSearchValue] = useState("");

  const defaultModalBody = [
    {
      id: "name",
      name: "name",
      type: "text",
      placeholder: "Nombre_Plantilla",
      value: "",
    },
    {
      id: "body",
      name: "body",
      type: "textarea",
      placeholder: "_*Hola*_\n\nLe informamos que fue APROBADO el trámite\nAsegurado *{{1}}*\nCédula *{{2}}*\nIniciado el día *{{3}}*\nSuma asegurada $*{{4}}*\n\n*_Gracias_*",
      value: "",
    },
    {
      id: "source",
      name: "source",
      type: "select-datasource",
      dataSource: "/sources",
      multiselect: false,
      value: "",
      defaultValue: "",
      limit: 1,
      placeholder: "Plantilla Fuente",
      nextSourceIds: ["parameters", "buttonparameters", "phoneparameter", "agentparameter"],
    },
    {
      id: "parameters",
      name: "parameters",
      type: "select",
      dataSource: "/renovaciones/polizas/columns",
      multiselect: true,
      value: "",
      defaultValue: [],
      limit: 7,
      placeholder: "Parametros",
    },
    {
      id: "image-video-select",
      name: "image-video-select",
      type: "select-show",
      defaultValue: [{ value: "image", label: "image" }],
      value: [{ value: "image", label: "image" }],
      optionIds: ["image", "video"]
    },
    {
      id: "image",
      name: "image",
      type: "text",
      placeholder: "https://ejemplo.com/imagen.png",
      value: "",
    },
    {
      id: "video",
      name: "video",
      type: "hidden",
      placeholder: "https://ejemplo.com/video.mp4",
      value: "",
    },
    {
      id: "buttonparameters",
      name: "buttonparameters",
      type: "select",
      dataSource: "/renovaciones/polizas/columns",
      multiselect: true,
      value: "",
      defaultValue: [],
      limit: 2,
      placeholder: "Parametros_Boton",
    },
    {
      id: "buttonparameterindex",
      name: "buttonparameterindex",
      type: "number",
      placeholder: "1 o 0",
      value: "0",
    },
    {
      id: "agentbuttonparameters",
      name: "agentbuttonparameters",
      type: "select",
      dataSource: "/template?listnames=1",
      multiselect: true,
      value: "",
      limit: 1,
      placeholder: "Parametros_Boton_Agente",
    },
    {
      id: "agentbuttonparameterindex",
      name: "agentbuttonparameterindex",
      type: "number",
      placeholder: "1 o 0",
      value: "0",
    },
    {
      id: "phoneparameter",
      name: "phoneparameter",
      type: "select",
      dataSource: "/renovaciones/polizas/columns",
      multiselect: false,
      value: "",
      limit: 1,
      placeholder: "Columna_Telefono",
    },
    {
      id: "agentparameter",
      name: "agentparameter",
      type: "select",
      dataSource: "/renovaciones/polizas/columns",
      multiselect: false,
      value: "",
      limit: 1,
      placeholder: "Columna_Agente",
    },
    {
      id: "collection",
      name: "collection",
      type: "hidden",
      value: "renovacion",
      defaultValue: "renovacion",
    },
    {
      id: "is_monday",
      name: "is_monday",
      type: "checkbox",
      value: false,
      placeholder: "Es plantilla de Monday?",
    },
    {
      id: "is_flow",
      name: "is_flow",
      type: "checkbox",
      value: false,
      placeholder: "Es plantilla de flow?",
    },
  ];

  const customActionTemplate = [
    {
      id: "name",
      name: "name",
      type: "text",
      placeholder: "Nombre_Plantilla",
      value: "",
    },
    {
      id: "body",
      name: "body",
      type: "textarea",
      placeholder: "_*Hola*_\n\nLe informamos que fue APROBADO el trámite\nAsegurado *{{1}}*\nCédula *{{2}}*\nIniciado el día *{{3}}*\nSuma asegurada $*{{4}}*\n\n*_Gracias_*",
      value: "",
    },
    {
      id: "parameters",
      name: "parameters",
      type: "select",
      dataSource: "/renovaciones/polizas/columns",
      multiselect: true,
      value: "",
      defaultValue: [],
      limit: 7,
      placeholder: "Parametros",
    },
    {
      id: "image",
      name: "image",
      type: "text",
      placeholder: "https://ejemplo.com/imagen.png",
      value: "",
    },
    {
      id: "buttonparameters",
      name: "buttonparameters",
      type: "select",
      dataSource: "/template?listnames=1",
      multiselect: true,
      value: "",
      limit: 2,
      placeholder: "Parametros_Boton",
    },
    {
      id: "buttonparameterindex",
      name: "buttonparameterindex",
      type: "number",
      placeholder: "1 o 0",
      value: "0",
    },
    {
      id: "agentbuttonparameters",
      name: "agentbuttonparameters",
      type: "select",
      dataSource: "/template?listnames=1",
      multiselect: true,
      value: "",
      limit: 1,
      placeholder: "Parametros_Boton_Agente",
    },
    {
      id: "agentbuttonparameterindex",
      name: "agentbuttonparameterindex",
      type: "number",
      placeholder: "1 o 0",
      value: "0",
    },
    {
      id: "template_origin",
      name: "template_origin",
      type: "select",
      dataSource: "/collections",
      multiselect: false,
      value: "",
      defaultValue: "",
      limit: 1,
      placeholder: "Coleccion",
    },
    {
      id: "type",
      name: "type",
      type: "hidden",
      value: "quickreply",
      defaultValue: "quickreply",
    },
    {
      id: "collection",
      name: "collection",
      type: "hidden",
      value: "renovacion",
      defaultValue: "renovacion",
    },
    {
      id: "is_monday",
      name: "is_monday",
      type: "checkbox",
      value: false,
      placeholder: "Es plantilla de Monday?",
    },
    {
      id: "is_flow",
      name: "is_flow",
      type: "checkbox",
      value: false,
      placeholder: "Es plantilla de flow?",
    },
  ];

  const searchTemplate = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setTemplates(tempTemplates);
    } else {
      setTemplates(
        tempTemplates.filter((template) =>
          template.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    if (loading) return;
    async function fetchData() {
      setLoading(true);
      const url = process.env.REACT_APP_BACKEND_API + "/template";
      let config = {
        headers: {
          "x-access-token": user.accessToken,
          "Content-Type": "application/json",
        },
      }
      let result = await axios.get(url, config)
        .then((response) => {
          return response;
        }
        )
        .catch((error) => {
          return error;
        }
        );
      if (result.data) {
        setLoading(false);
        setTemplates(result.data);
        setTempTemplates(result.data);
      }
    }
    fetchData();
  }, []);


  const formOnChange = (e, action) => {
    try {
      const { name, value } = e.target;
      setModalBody(
        modalBody.map((item) => {
          if (item.name === name) {
            item.value = value;
          }
          return item;
        })
      );
    } catch (error) {
      const items = e;
      setModalBody(
        modalBody.map((bodyItem) => {
          if (
            (bodyItem.type === "select" || bodyItem.type === "select-show" || bodyItem.type === "select-datasource") &&
            bodyItem.name === action.name
          ) {
            bodyItem.value = items;
            return bodyItem;
          }
          return bodyItem;
        })
      );
    }
  };

  const newTemplate = (e) => {
    e.preventDefault();
    setModalBody(defaultModalBody);
    setCurrentTitle("Nueva Plantilla");
    setCurrentFormAction("/");
    setCurrentFormMethod("POST");
    setCurrentButtonText("Guardar");
    openModal();
  };

  const newTemplateQuickReply = (e) => {
    e.preventDefault();
    setModalBody(customActionTemplate);
    setCurrentTitle("Nueva Plantilla Quick Reply");
    setCurrentFormAction("/");
    setCurrentFormMethod("POST");
    setCurrentButtonText("Guardar");
    openModal();
  };

  const deleteTemplate = (e) => {
    e.preventDefault();
    const id = templates[e.currentTarget.dataset.id]._id;
    fetch(process.env.REACT_APP_BACKEND_API + "/template/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'x-access-token': user.accessToken,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTemplates(templates.filter((template) => template._id !== id));
        setTempTemplates(tempTemplates.filter((template) => template._id !== id));
      });
  };

  const editTemplate = (e) => {
    e.preventDefault();
    const id = templates[e.currentTarget.dataset.id]._id;
    setCurrentFormAction("/" + id);
    setCurrentFormMethod("PUT");
    setCurrentButtonText("Actualizar");
    try {
      if (templates[e.currentTarget.dataset.id].type === "quickreply") {
        setCurrentTitle("Editar Plantilla Quick Reply");
        setModalBody(
          //avoid + field type password
          customActionTemplate
            .filter((item) => item.name !== "password")
            .map((item) => {
              try {
                item.value = templates[e.currentTarget.dataset.id][item.name]
                  ? templates[e.currentTarget.dataset.id][item.name]
                  : "";
                //check if the property item.defaultValue exists
                if (item.defaultValue) {
                  item.defaultValue = templates[e.currentTarget.dataset.id][item.name]
                    ? templates[e.currentTarget.dataset.id][item.name].map(
                      (optionItem) => {
                        return {
                          value: optionItem,
                          label: optionItem,
                          disabled: false,
                        };
                      }
                    )
                    : [];

                }
              } catch (error) {

              }
              return item;
            })
        );
      } else {
        //avoid the field type password
        let newModalBody = defaultModalBody
          .filter((item) => item.name !== "password")
          .map((item) => {
            try {
              item.value = templates[e.currentTarget.dataset.id][item.name]
                ? templates[e.currentTarget.dataset.id][item.name]
                : "";
              //check if the property item.defaultValue exists
              if (item.defaultValue) {
                item.defaultValue = templates[e.currentTarget.dataset.id][item.name]
                  ? (Array.isArray(templates[e.currentTarget.dataset.id][item.name]) ? templates[e.currentTarget.dataset.id][item.name].map(
                    (optionItem) => {
                      return {
                        value: optionItem,
                        label: optionItem,
                        disabled: false,
                      };
                    }
                  ) : [])
                  : [];
              }
            } catch (error) {
              console.log("error en modal");
            }
            return item;
          })
        setModalBody(newModalBody);
      }

    } catch (error) {
      console.log(error);
    }
    openModal();
  };

  const cleanModalForm = () => {
    setModalBody(
      modalBody.map((item) => {
        item.value = "";
        return item;
      })
    );
  };

  const submitTemplate = (e) => {
    e.preventDefault();
    setModalSubmitting(true);
    /*const form = e.target;
    const data = new FormData(form);
    const value = Object.fromEntries(data.entries());
    console.log(value);*/
    const value = modalBody.reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});
    //send the data post to endpoint /template
    fetch(process.env.REACT_APP_BACKEND_API + "/template" + currentFormAction, {
      method: currentFormMethod,
      headers: {
        "Content-Type": "application/json",
        'x-access-token': user.accessToken,
      },
      body: JSON.stringify(value),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (currentFormMethod == "POST") {
          setTemplates([...templates, data]);
          setTempTemplates([...tempTemplates, data]);
          setModalResult("Template creado correctamente");
        } else if (currentFormMethod == "PUT") {
          setTemplates(
            templates.map((template) => {
              if (template._id === data._id) {
                //only update the fields that are not empty
                for (const key in data) {
                  if (data[key] !== "") {
                    template[key] = data[key];
                  }
                }
                return template;
              }
              return template;
            })
          );
          setTempTemplates(
            templates.map((template) => {
              if (template._id === data._id) {
                //only update the fields that are not empty
                for (const key in data) {
                  if (data[key] !== "") {
                    template[key] = data[key];
                  }
                }
                return template;
              }
              return template;
            })
          );
          setModalResult("Ejecutivo actualizado correctamente");
        }
        //disvanish the modal result at 3 seconds
        setTimeout(() => {
          setModalResult("");
        }, 3000);
        cleanModalForm();
        setModalSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setModalResult(err.message);
        setModalSubmitting(false);
      });
  };
  const openModal = () => {
    const modal = document.querySelector(".main-modal");
    modal.classList.remove("fadeOut");
    modal.classList.add("fadeIn");
    modal.style.display = "flex";
  };

  const closeModal = () => {
    const modal = document.querySelector(".main-modal");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");
    modal.style.display = "none";
    setModalBody(defaultModalBody);
  };

  return (
    <div>
      <div className="bg-white p-8 rounded-md w-full">
        <div className="md:flex items-center justify-between pb-6">
          <div>
            <h2 className="text-gray-600 font-semibold">Templates</h2>
            <span className="text-xs">
              Seleccione y edite plantillas de Whatsapp Business
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex bg-gray-50 items-center p-2 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
              <input
                className="bg-gray-50 outline-none ml-1 block "
                type="text"
                name=""
                id=""
                onChange={searchTemplate}
                value={searchValue}
                placeholder="Buscar..."
              />
            </div>
            <div className="lg:ml-40 ml-10 space-x-8">
              <button
                onClick={newTemplate}
                className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
              >
                Nueva Plantilla
              </button>
            </div>
            <div className="lg:ml-4 ml-1 space-x-8">
              <button
                onClick={newTemplateQuickReply}
                className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
              >
                Nueva Plantilla de respuestas rapidas
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Nombre
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Tipo
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Body
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Created at
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Image Header
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Parameters
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Buttons
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase">
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map((template, key) => {
                    return (
                      <tr>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {template.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {template.type ? template.type : "button"}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {template.parameters
                              ? template.parameters.map((parameter, key) => {
                                return parameter.value + " ";
                              })
                              : "No tiene parametros asignados"}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {template.created_at}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {template.image && <img src={template.image} alt={template.image} width="100" height="100" />}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-green-200 opacity-50 rounded"
                            ></span>
                            <span className="relative">
                              {template.parameters
                                ? template.parameters.map(
                                  (parameter, key) => {
                                    return parameter.value + " ";
                                  }
                                )
                                : "No tiene parametros asignados"}
                            </span>
                          </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span
                              aria-hidden
                              className="absolute inset-0 bg-green-200 opacity-50 rounded"
                            ></span>
                            <span className="relative">
                              {template.buttonparameters
                                ? template.buttonparameters.map(
                                  (parameter, key) => {
                                    return parameter.value + " ";
                                  }
                                )
                                : "No tiene parametros asignados"}
                            </span>
                          </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                          <div
                            className="inline-flex shadow-md hover:shadow-lg focus:shadow-lg"
                            role="group"
                          >
                            <button
                              onClick={editTemplate}
                              data-id={key}
                              type="button"
                              className="rounded-l inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out"
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              onClick={deleteTemplate}
                              data-id={key}
                              className="rounded-r inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase hover:bg-red-700 focus:bg-red-700 focus:outline-none focus:ring-0 active:bg-red-800 transition duration-150 ease-in-out"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewModelModal
        title={currentTitle}
        onClose={closeModal}
        body={modalBody}
        onSubmit={submitTemplate}
        modalResult={modalResult}
        onChange={formOnChange}
        method={currentFormMethod}
        action={currentFormAction}
        buttonText={currentButtonText}
      />
    </div>
  );
}
