import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth } from "../services/auth.service";
import axios from 'axios';

export default function TemplateMessage(props) {

    const [parameters, setParameters] = useState(null);
    const [inputTemplate, setInputTemplate] = useState(null);
    const [textBody, setTextBody] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const { user, logout, currentConfig, changeCurrentConfig, getCurrentConfig } = useAuth();

    useEffect(() => {
        setParameters(props.message.text.body.components[1].parameters);
    }, [props.message.id]);

    useEffect(() => {
        const outputElement = document.getElementById(props.message.id);
        if (props.message.text.body.editable && outputElement) {
            let templateinput = localStorage.getItem(props.message.text.body.name);
            templateinput = JSON.parse(templateinput);
            let inputcleanparameters = [];
            templateinput.parameters.forEach((param, index) => {
                inputcleanparameters.push({ type: "text", text: `variable ${index + 1}` });
            });
            let useparameters = parameters;
            if (props.message.text.body.editable)
                useparameters = inputcleanparameters;
            //set body text
            let reducedParameters = useparameters.reduce((acc, param, index) => { acc[index + 1] = param.text; return acc; }, {});
            outputElement.addEventListener('keydown', function (event) {
                const target = event.target;
                if (target.tagName === 'SPAN' && target.getAttribute('contenteditable') === 'true' && event.key === 'Enter') {
                    event.preventDefault();
                    target.blur();
                    return false;
                }
            });
            outputElement.addEventListener("input", (event) => {
                const target = event.target;
                if (target.tagName === "SPAN" && target.getAttribute('contenteditable') === 'true') {
                    const index = target.dataset.index;
                    const newValue = target.innerText;
                    // Validate input against the pattern
                    const pattern = /^[\w\s!@#$%^&*()-]+$/; // Alphanumeric, spaces, and a few special characters
                    if (!pattern.test(newValue)) {
                        // Invalid input, remove the last character
                        target.innerText = newValue.slice(0, -1);
                        return;
                    }
                    reducedParameters[index] = newValue;
                    //transform redured parameters to array

                    localStorage.setItem('variables', JSON.stringify(Object.entries(reducedParameters).map(([key, value]) => ({
                        type: "text",
                        value: value
                    }))));
                }
            });
        }
    }, [textBody]);

    useEffect(() => {
        if (!parameters && !props.message.text.body.editable) return;
        //get input template from local storage
        let templateinput = localStorage.getItem(props.message.text.body.name);
        const editable = props.message.text.body.editable ? props.message.text.body.editable : false;
        setIsEditable(editable);
        if (!templateinput || templateinput === "undefined")
            templateinput = null;
        if (JSON.parse(templateinput)) {
            try {
                templateinput = JSON.parse(templateinput);
                let inputcleanparameters = [];
                templateinput.parameters.forEach((param, index) => {
                    inputcleanparameters.push({ type: "text", text: `variable ${index + 1}` });
                });
                let useparameters = parameters;
                if (props.message.text.body.editable)
                    useparameters = inputcleanparameters;
                setInputTemplate(templateinput);
                //set body text
                let reducedParameters = useparameters.reduce((acc, param, index) => { acc[index + 1] = param.text; return acc; }, {});
                if (templateinput.body === "") {
                    setTextBody(templateinput.name + " " + props.message.text.body.components[1].parameters.map(item => item.text).join(" "));
                    return;
                }
                let translated = translate(templateinput.body, reducedParameters, editable);
                setTextBody(translated);
            } catch (error) {
                console.log(error);
            }

        } else {
            //get input template from api
            const url = process.env.REACT_APP_BACKEND_API + "/template?name=" + props.message.text.body.name;
            const config = {
                headers: {
                    'x-access-token': user.accessToken,
                    'Content-Type': 'application/json',
                }
            }
            let response = axios.get(url, config);
            response.then((data) => {
                try {
                    if (!data) return;
                    let template = data.data;
                    setInputTemplate(template[0]);
                    localStorage.setItem(props.message.text.body.name, JSON.stringify(template[0]));
                    let reducedParameters = parameters.reduce((acc, param, index) => { acc[index + 1] = param.text; return acc; }, {});
                    if (template[0].body === "") {
                        setTextBody(template[0].name + " " + props.message.text.body.components[1].parameters.map(item => item.text).join(" "));
                        return;
                    }
                    let translated = translate(template[0].body, reducedParameters);
                    setTextBody(translated);
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }, [parameters]);

    function translate(input, variables, editable) {
        // Replace placeholders with variables
        let translated = input.replace(/\{\{(\d+)\}\}/g, (match, number) => {
            if (!editable)
                return variables[number] || match;
            const variable = variables[number];
            if (variable) {
                return `<span contenteditable="true" placeholder="variable ${number}" data-index="${number}" style="white-space: nowrap;">variable ${number}</span>`;
            }
            return match;
        });

        // Replace bold and italic formatting
        translated = translated.replace(/\*(.*?)\*/g, '<b>$1</b>');
        translated = translated.replace(/_(.*?)_/g, '<i>$1</i>');

        // Combine remaining formatting
        translated = translated.replace(/(<i>.*)(<b>.*<\/b>.*<\/i>)/g, '$2$1');

        return translated;
    }


    return (
        textBody ?
            <>
                <div id={props.message.id} dangerouslySetInnerHTML={{ __html: textBody }}></div>
                {isEditable ?
                    <div className="text-xs text-gray-500 mt-4">
                        <button
                            onClick={props.sendTemplateMessage}
                            className='text-xs text-gray-500 hover:text-gray-700'
                        >
                            Enviar
                        </button>
                        <button
                            onClick={props.cancelTemplateMessage}
                            className='text-xs ml-4 text-gray-500 hover:text-gray-700'
                        >
                            Cancelar
                        </button>
                    </div> : <></>}
            </> :
            <div></div>
    )
}