import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth } from "../services/auth.service";
import axios from 'axios';

export default function SurveyMessage(props) {
    const { user } = useAuth();
    const [survey, setSurvey] = React.useState();
    const [responseJson, setResponseJson] = React.useState(props.responseJson);
    const [localData, setLocalData] = React.useState(null);

    useEffect(() => {
        if (localData) {
            setSurvey(fetchSurvey());
        }
        else {
            getSurvey();
        }
    }, [localData]);

    const getSurvey = () => {
        //check if the survey exist on localstorage
        const surveyId = "65cfa291d2cf9544d0ee5667";

        const surveyData = localStorage.getItem(surveyId);

        if (surveyData) {
            setLocalData(JSON.parse(surveyData));
            return;
        }
        const response = axios.get(`${process.env.REACT_APP_BACKEND_API}/surveys/${surveyId}`, {
            headers: {
                "Content-Type": "application/json",
                'x-access-token': user.accessToken,
            }
        });
        response.then((data) => {
            setLocalData(data.data);
            localStorage.setItem(surveyId, JSON.stringify(data.data));
        });
    }

    const fetchSurvey = () => {

        const responseObject = JSON.parse(responseJson);

        //query the survey endpoint 65cfa291d2cf9544d0ee5667


        // Extract the responses from the object
        const responses = Object.entries(responseObject).map(([question, answer]) => {
            try {
                return (
                    (question != 'flow_token' && question != 'wa_flow_response_params') &&
                    <li key={question}>
                        <strong>{localData.screens[question.split('_')[1]].layout.children[0].children[0].text}:</strong> {Array.isArray(answer) ? answer.join(', ') : (localData.screens[question.split('_')[1]].layout.children[0].children[1]['data-source'] ? localData.screens[question.split('_')[1]].layout.children[0].children[1]['data-source'].find(item => item.id === answer).title : 'Sin respuesta')}
                    </li>
                )
            } catch (error) {
                return (
                    question != 'flow_token' &&
                    <li key={question}>
                        <strong>{localData.screens[question.split('_')[1]].layout.children[0].children[0].text}:</strong> Sin respuesta
                    </li>
                )
            }
        });

        return (
            <div>
                <h2>Questionnaire Responses</h2>
                <ul>{responses}</ul>
            </div>
        );
    }

    return (
        survey ? survey : (<div>Loading...</div>)
    )
}