import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export function DatePickerDayOfMonth(props) {
    const [value, setValue] = React.useState(dayjs('2024-03'));
    const handleChange = (newValue) => {
        setValue(newValue);
        props.onChange(newValue)
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={'Día del mes (se adapta a cualquier mes)'}
                views={['day']}
                value={value}
                onChange={(newValue) => handleChange(newValue)}
            />
        </LocalizationProvider>
    );
}

export default DatePickerDayOfMonth;