import React from 'react';
import { MarkerType, Position } from 'reactflow';

export const nodes = [
    {
        id: '1',
        type: 'custom',
        position: { x: 100, y: 200 },
        data: {
            selects: {
                'handle-0': 'smoothstep',
            },
        },
    },
];

export const edges = [
];