import React, { useState, useEffect } from 'react';
import { useAuth } from "../services/auth.service";

const Automatizaciones = () => {
    const [records, setRecords] = useState([]);
    const { user, logout } = useAuth();

    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_BACKEND_API}/renovaciones/automatizaciones`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setRecords(data);
            });
    });

    const handleDelete = (id) => {
        fetch(
            `${process.env.REACT_APP_BACKEND_API}/renovaciones/automatizaciones/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            }
        );
        const updatedRecords = records.filter((record) => record._id !== id);
        setRecords(updatedRecords);
    };

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
                <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-xs leading-normal">
                        <th className="py-3 px-6 text-left">_id</th>
                        <th className="py-3 px-6 text-left">filtros</th>
                        <th className="py-3 px-6 text-left">plantilla</th>
                        <th className="py-3 px-6 text-left">hora</th>
                        <th className="py-3 px-6 text-left">dia</th>
                        <th className="py-3 px-6 text-left">Diferencia de Mes</th>
                        <th className="py-3 px-6 text-left">Diferencia de Anho</th>
                        <th className="py-3 px-6 text-left">Actions</th>
                    </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                    {records.map((record) => (
                        <tr key={record._id} className="border-b border-gray-200 hover:bg-gray-100">
                            <td className="py-3 px-6 text-left">{record._id}</td>
                            <td className="py-3 px-6 text-left">
                                {record.filtros.map((filtro, index) => (
                                    <div key={index}>
                                        <span>{filtro.tipo}: </span>
                                        <span>{filtro.valor}</span>
                                    </div>
                                ))}
                            </td>
                            <td className="py-3 px-6 text-left">{record.plantilla}</td>
                            <td className="py-3 px-6 text-left">{record.hora}</td>
                            <td className="py-3 px-6 text-left">{record.dia}</td>
                            <td className="py-3 px-6 text-left">{record.monthDiff}</td>
                            <td className="py-3 px-6 text-left">{record.yearDiff}</td>
                            <td className="py-3 px-6 text-left">
                                <button
                                    onClick={() => handleDelete(record._id)}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Eliminar
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Automatizaciones;
