import axios from "axios";
import React, { useState, useEffect } from "react";
import NewModelModal from "./Modal";
import { useAuth } from "../services/auth.service";

export default function Subscriptions() {
    const [modalSubmitting, setModalSubmitting] = useState(false);
    const [modalResult, setModalResult] = useState("");
    const [subscriptions, setSubscriptions] = useState([]);
    const [currentFormAction, setCurrentFormAction] = useState("/");
    const [currentFormMethod, setCurrentFormMethod] = useState("POST");
    const [currentButtonText, setCurrentButtonText] = useState("Guardar");
    const [modalBody, setModalBody] = useState([]);
    const { user, logout, currentConfig, changeCurrentConfig, getCurrentConfig } = useAuth();
    const defaultModalBody = [
        {
            id: "name",
            name: "name",
            type: "text",
            placeholder: "Nombre",
            value: "",
        },
        {
            id: "url",
            name: "url",
            type: "text",
            placeholder: "url destino",
            value: "",
        },
        {
            id: "method",
            name: "method",
            type: "select-fixed",
            dataSource: "",
            multiselect: false,
            value: "",
            defaultValue: "",
            limit: 1,
            placeholder: "Metodo de llamada",
            options: ['GET', 'POST', 'PUT', 'DELETE'],
        },
        {
            id: "body",
            name: "body",
            type: "textarea",
            placeholder: '{"IdPoliza": {{1}}, "Descripcion": {{2}}, "IdTipoGestion": {{3}}, "FechaProxAcc": ""}',
            value: "",
        },
        {
            id: "source",
            name: "source",
            type: "select-datasource",
            dataSource: "/sources",
            multiselect: false,
            value: "",
            defaultValue: "",
            limit: 1,
            placeholder: "Plantilla Fuente",
            nextSourceIds: ["params", "buttonparameters", "phoneparameter", "agentparameter"],
        },
        {
            id: "params",
            name: "params",
            type: "select",
            dataSource: "/renovaciones/polizas/columns",
            multiselect: true,
            value: "",
            defaultValue: [],
            limit: 7,
            placeholder: "Parametros",
        },
        {
            id: "template_origin",
            name: "template_origin",
            type: "select",
            dataSource: "/collections",
            multiselect: false,
            value: "",
            defaultValue: "",
            limit: 1,
            placeholder: "Coleccion",
        }
    ];

    useEffect(() => {
        async function fetchData() {
            const url = process.env.REACT_APP_BACKEND_API + "/subscription";
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            };
            const result = await axios.get(url, config);
            if (result.data.length > 0) {
                setSubscriptions(result.data);
            }
        }
        fetchData();
    }, []);

    const formOnChange = (e, action) => {
        try {
            const { name, value } = e.target;
            setModalBody(
                modalBody.map((item) => {
                    if (item.name === name) {
                        item.value = value;
                    }
                    return item;
                })
            );
        } catch (error) {
            const items = e;
            setModalBody(
                modalBody.map((bodyItem) => {
                    if (
                        (bodyItem.type === "select" || bodyItem.type === "select-show" || bodyItem.type === "select-datasource" || bodyItem.type === "select-fixed") &&
                        bodyItem.name === action.name
                    ) {
                        bodyItem.value = items;
                        return bodyItem;
                    }
                    return bodyItem;
                })
            );
        }
    };

    const newSubscription = (e) => {
        e.preventDefault();
        setModalBody(defaultModalBody);
        setCurrentFormAction("/");
        setCurrentFormMethod("POST");
        setCurrentButtonText("Guardar");
        openModal();
    };

    const deleteSubscription = (e) => {
        e.preventDefault();
        const id = subscriptions[e.currentTarget.dataset.id]._id;
        const url = process.env.REACT_APP_BACKEND_API + "/subscription/" + id;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "x-access-token": user.accessToken,
            },
        };
        let result = axios.delete(url, config);
        if (result)
            setSubscriptions(subscriptions.filter((subscription) => subscription._id !== id));
    };

    const editSubscription = (e) => {
        e.preventDefault();
        const id = subscriptions[e.currentTarget.dataset.id]._id;
        setCurrentFormAction("/" + id);
        setCurrentFormMethod("PUT");
        setCurrentButtonText("Actualizar");
        setModalBody(
            //avoid the field type password
            defaultModalBody
                .filter((item) => item.name !== "password")
                .map((item) => {
                    item.value = subscriptions[e.currentTarget.dataset.id][item.name]
                        ? subscriptions[e.currentTarget.dataset.id][item.name]
                        : "";
                    //check if the property item.defaultValue exists
                    if (item.defaultValue) {
                        item.defaultValue = subscriptions[e.currentTarget.dataset.id][item.name]
                            ? subscriptions[e.currentTarget.dataset.id][item.name].map(
                                (optionItem) => {
                                    return {
                                        value: optionItem,
                                        label: optionItem,
                                        disabled: false,
                                    };
                                }
                            )
                            : [];
                    }
                    return item;
                })
        );
        openModal();
    };

    const cleanModalForm = () => {
        setModalBody(
            modalBody.map((item) => {
                item.value = "";
                return item;
            })
        );
    };

    const submitSubscription = (e) => {
        e.preventDefault();
        setModalSubmitting(true);
        /*const form = e.target;
        const data = new FormData(form);
        const value = Object.fromEntries(data.entries());
        console.log(value);*/
        const value = modalBody.reduce((acc, item) => {
            acc[item.name] = item.value;
            return acc;
        }, {});
        //send the data post to endpoint /subscription
        fetch(
            process.env.REACT_APP_BACKEND_API + "/subscription" + currentFormAction,
            {
                method: currentFormMethod,
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
                body: JSON.stringify(value),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (currentFormMethod == "POST") {
                    setModalResult(data.message);
                    setSubscriptions([...subscriptions, data.subscription]);
                }
                if (currentFormMethod == "PUT") {
                    setSubscriptions(
                        subscriptions.map((subscription) => {
                            if (subscription._id === data._id) {
                                //only update the fields that are not empty
                                for (const key in data) {
                                    if (data[key] !== "") {
                                        subscription[key] = data[key];
                                    }
                                }
                                return subscription;
                            }
                            return subscription;
                        })
                    );
                    setModalResult("Subscripcion actualizado correctamente");
                }
                //disvanish the modal result at 3 seconds
                setTimeout(() => {
                    setModalResult("");
                }, 3000);
                cleanModalForm();
                setModalSubmitting(false);
            })
            .catch((err) => {
                console.log(err);
                setModalResult(err.message);
                setModalSubmitting(false);
            });
    };
    const openModal = () => {
        const modal = document.querySelector(".main-modal");
        modal.classList.remove("fadeOut");
        modal.classList.add("fadeIn");
        modal.style.display = "flex";
    };

    const closeModal = () => {
        const modal = document.querySelector(".main-modal");
        modal.classList.remove("fadeIn");
        modal.classList.add("fadeOut");
        modal.style.display = "none";
        setModalBody(defaultModalBody);
    };

    return (
        <div>
            <div className="bg-white p-8 rounded-md w-full">
                <div className="md:flex items-center justify-between pb-6">
                    <div>
                        <h2 className="text-gray-600 font-semibold">Subscripciones</h2>
                        <span className="text-xs">
                            Seleccione y asigne subscriciones
                        </span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex bg-gray-50 items-center p-2 rounded-md">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            <input
                                className="bg-gray-50 outline-none ml-1 block "
                                type="text"
                                name=""
                                id=""
                                placeholder="Buscar..."
                            />
                        </div>
                        <div className="lg:ml-40 ml-10 space-x-8">
                            <button
                                onClick={newSubscription}
                                className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
                            >
                                Nueva Subscripcion
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Nombre
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            URL
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                            Created at
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase">
                                            Options
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptions && subscriptions.map((subscription, key) => {
                                        return (
                                            <tr>
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 w-10 h-10">
                                                            <img
                                                                className="w-full h-full rounded-full"
                                                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="ml-3">
                                                            <p className="text-gray-900 whitespace-no-wrap">
                                                                {subscription.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {subscription.url}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div className="flex items-center">
                                                        <div className="ml-3">
                                                            <p className="text-gray-900 whitespace-no-wrap">
                                                                {subscription.createdAt}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                                                    <div
                                                        className="inline-flex shadow-md hover:shadow-lg focus:shadow-lg"
                                                        role="group"
                                                    >
                                                        <button
                                                            onClick={editSubscription}
                                                            data-id={key}
                                                            type="button"
                                                            className="rounded-l inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out"
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={deleteSubscription}
                                                            data-id={key}
                                                            className="rounded-r inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase hover:bg-red-700 focus:bg-red-700 focus:outline-none focus:ring-0 active:bg-red-800 transition duration-150 ease-in-out"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <NewModelModal
                title={`Crear Subscripcion`}
                onClose={closeModal}
                body={modalBody}
                onSubmit={submitSubscription}
                modalResult={modalResult}
                onChange={formOnChange}
                method={currentFormMethod}
                action={currentFormAction}
                buttonText={currentButtonText}
            />
        </div>
    );
}
