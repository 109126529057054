import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSpinner, faFilter } from "@fortawesome/free-solid-svg-icons";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { useAuth } from "../services/auth.service";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Pagination } from "@mui/material";
//create a react component who loads data from axios and render it as a table
export default function CustomTable() {
    const [tableItems, setTableItems] = useState([]);
    const [tableItemsFiltered, setTableItemsFiltered] = useState([]);
    const [filters, setFilters] = useState([]);
    const { user } = useAuth();
    const params = useParams();
    const [tablename, setTablename] = useState(params.tablename);
    const [columns, setColumns] = useState([]);

    const [dataColumns, setDataColumns] = useState([]);
    const [selectedDataColumns, setSelectedDataColumns] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]); //plain array
    const [sortedAs, setSortedAs] = useState("asc");
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [sentItems, setSentItems] = useState(0);
    const [modalMessageBody, setModalMessageBody] = useState("");
    const [skip, setSkip] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const assignTemplate = async (e) => {
        e.preventDefault();
        const url = `${process.env.REACT_APP_BACKEND_API}/customtableselecttemplate?tablename=${tablename}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user.accessToken,
            },
            body: selectedTemplate,
        };
        const response = await axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user.accessToken,
            },
            url: `${process.env.REACT_APP_BACKEND_API}/customtableselecttemplate?tablename=${tablename}`,
            data: selectedTemplate,
        }
        );
        if (response.status == 200) {
            setModalMessageBody("Plantilla asignada correctamente");
            openModal();
        }
    }

    useEffect(() => {
        //check if tableItems is not empty
        if (tableItems.length > 0) {
            let tempDataColumns = dataColumns;
            columns.forEach((column) => {
                let items = [];
                if (!column.singleItem)
                    items = Array.from(
                        new Set(tableItems.map((item) => item[column.selector]))
                    );
                //convert items to array

                tempDataColumns[column.selector] = items;
            });
            setDataColumns(tempDataColumns);
        }
        // let aseguradoras = [
        //   ...new Set(tableItems.map((poliza) => poliza.Aseguradora)),
        // ];
        // setAseguradoras(aseguradoras);
        // //get from polizas the distinct statuses
        // let statuses = [...new Set(tableItems.map((poliza) => poliza.Estatus))];
        // setStatuses(statuses);
        // // //get from polizas the distinct tipos
        // let tipos = [...new Set(tableItems.map((poliza) => poliza.Tipo_Renovacion))];
        // setTipos(tipos);
        // // //get from polizas the distinct ramos
        // let ramos = [...new Set(tableItems.map((poliza) => poliza.Ramo))];
        // setRamos(ramos);
        // let sucursales = [...new Set(tableItems.map((poliza) => poliza.Sucursal))];
        // setSucursales(sucursales);
        // let MensajesStatus = [
        //   ...new Set(tableItems.map((poliza) => poliza.Mensaje_Status)),
        // ];
        // setMensajesStatus(MensajesStatus);

        setTableItemsFiltered(tableItems);
    }, [tableItems]);

    useEffect(() => {
        //get from polizas the distinct aseguradoras
        // let aseguradoras = [
        //   ...new Set(polizasFiltered.map((poliza) => poliza.Aseguradora)),
        // ];
        // setAseguradoras(aseguradoras);
        // //get from polizas the distinct statuses
        // let statuses = [
        //   ...new Set(polizasFiltered.map((poliza) => poliza.Estatus)),
        // ];
        // setStatuses(statuses);
        // //get from polizas the distinct tipos
        // let tipos = [
        //   ...new Set(polizasFiltered.map((poliza) => poliza.Tipo_Renovacion)),
        // ];
        // setTipos(tipos);
        // //get from polizas the distinct ramos
        // let ramos = [...new Set(polizasFiltered.map((poliza) => poliza.Ramo))];
        // setRamos(ramos);
        // let sucursales = [
        //   ...new Set(polizasFiltered.map((poliza) => poliza.Sucursal)),
        // ];
        // setSucursales(sucursales);
    }, [tableItemsFiltered]);

    useEffect(() => {
        async function fetchData() {
        await fetch(
            `${process.env.REACT_APP_BACKEND_API}/customtable?tablename=${tablename}&skip=${skip}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => setTableItems(data));
        }
        fetchData();
    }, [skip]);

    useEffect(() => {
        async function fetchData() {
            await fetch(`${process.env.REACT_APP_BACKEND_API}/countcustomtable?tablename=${tablename}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data)
                        setTotalItems(data);
                }
                )
            //load selectedtemplate columns
            await fetch(`${process.env.REACT_APP_BACKEND_API}/customtablecolumns?tablename=${tablename}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data)
                        setColumns(data.map((column) => { return { name: column._id, selector: column._id, singleItem: true, isMulti: false, sortable: false } }));
                }
                )
            //load table items
            await fetch(
                `${process.env.REACT_APP_BACKEND_API}/customtable?tablename=${tablename}&skip=${skip}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": user.accessToken,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => setTableItems(data));

            //load selectedtemplate
            await fetch(`${process.env.REACT_APP_BACKEND_API}/customtableselecttemplate?tablename=${tablename}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data)
                        setSelectedTemplate(data);
                }
                )
            //load templates
            await fetch(`${process.env.REACT_APP_BACKEND_API}/template`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
            })
                .then((response) => response.json())
                .then((data) => setTemplates(data));
        }
        fetchData();
    }, []);

    const SelectCheckOptions = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const selectTemplate = (e) => {
        const templateID = e.target.value;
        const template = templates.find((template) => template._id == templateID);
        setSelectedTemplate(template);
    };

    const openModal = () => {
        const modal = document.querySelector(".main-modal");
        modal.classList.remove("fadeOut");
        modal.classList.add("fadeIn");
        modal.style.display = "flex";
    };

    const closeModal = () => {
        const modal = document.querySelector(".main-modal");
        modal.classList.remove("fadeIn");
        modal.classList.add("fadeOut");
        setTimeout(() => {
            modal.style.display = "none";
        }, 500);
    };

    const handleCheck = (e) => {
        const id = e.target.dataset.id;
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item != id));
        }
    };

    const checkAll = (e) => {
        //check all the checkboxes
        console.log(checkedItems);
        const isChecked = e.target.checked;
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let newCheckedItems = [];
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked != isChecked) {
                newCheckedItems.push(checkbox.dataset.id);
                checkbox.checked = isChecked;
            }
        });
        if (!isChecked) {
            setCheckedItems([]);
            return;
        }
        setCheckedItems([...newCheckedItems]);
    };

    const handleSearch = (e) => {
        const search = e.target.value;
        console.log(search);
    };

    const handleMultipleFilter = (e, action) => {
        const filter = filters;
        let filtered = tableItemsFiltered.filter((row) => {
            for (let i = 0; i < filter.length; i++) {
                let filterFlag = false;
                for (const entry of document.getElementsByName(filter[i]).values()) {
                    if (row[filter[i]] == entry.value) {
                        filterFlag = true;
                    }
                }
                if (!filterFlag)
                    return false;
            }
            return true;
        });
        setTableItemsFiltered(filtered);
        return;
    };

    const handleFilter = (e, action) => {
        try {
            const filter = e.target.value;
            const type = e.target.dataset.tipo;
            let filtered = tableItemsFiltered.filter((row) => {
                if (row[type] == filter) {
                    return true;
                }
                return false;
            });
            setTableItemsFiltered(filtered);
            return;
        } catch (error) {

            let newSelectedDataCols = selectedDataColumns;
            newSelectedDataCols[action.name] = e;
            setSelectedDataColumns({ ...newSelectedDataCols });
            const filter = action.name;
            const filterExists = filters.find((item) => {
                if (item == filter) {
                    return true;
                }
                return false;
            });
            if (!filterExists) {
                setFilters([...filters, filter]);
            } else if (filterExists && e.length == 0) {
                setFilters(filters.filter((item) => item != filter));
            }

        }
    };

    const sortData = (e) => {
        const type = e.target.dataset.tipo;
        return;
    };

    const resetFilters = () => {
        setTableItemsFiltered(tableItems);
        //put all select dropdowns on first value
        const selects = document.querySelectorAll("select");
        selects.forEach((select) => {
            select.selectedIndex = 0;
        });
        let newSelectedDataCols = selectedDataColumns;
        //remove the values for all items
        for (const key in newSelectedDataCols) {
            newSelectedDataCols[key] = [];
        }
        setSelectedDataColumns({ ...newSelectedDataCols });
        setFilters([]);
    };

    return (
        <>
            <div className="bg-white p-8 rounded-md w-full">
                <div className=" flex items-center justify-between pb-6"></div>
                <div>
                    <h2 className="text-gray-600 font-semibold">{tablename}</h2>
                    <span className="text-xs">
                        {checkedItems.length > 0
                            ? checkedItems.length + " Seleccionados"
                            : "Seleccione y asigne plantillas"}
                    </span>
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex bg-gray-50 items-center p-2 rounded-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                            />
                        </svg>
                        <input
                            onChange={handleSearch}
                            className="bg-gray-50 outline-none ml-1 block "
                            type="text"
                            name=""
                            id=""
                            placeholder="Buscar..."
                        />
                    </div>
                    <div className="lg:ml-40 ml-10 space-x-8">
                        <select onChange={selectTemplate}>
                            <option value="">Seleccione plantilla</option>
                            {selectedTemplate && templates.map((template) => {
                                selectedTemplate._id === template._id ? template.selected = true : template.selected = false;
                                return <option value={template._id} selected={template.selected}>{template.name}</option>;
                            })}
                        </select>
                        <button
                            onClick={assignTemplate}
                            className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
                        >
                            Assign Template
                        </button>
                        <button
                            className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={resetFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} /> Reset Filters
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-x-auto rounded-lg">
                                <table className="min-w-full">
                                    <thead className="border-b">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                #
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <input type="checkbox" onChange={checkAll} />
                                            </th>
                                            {selectedTemplate.phoneparameter ? (selectedTemplate.phoneparameter.label ? <th scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100">Chat</th> : '') : ''}
                                            <th scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100">Status</th>

                                            {Object.keys(dataColumns).length > 0
                                                ? columns.map((column) => {
                                                    if (column.singleItem)
                                                        return (
                                                            <th
                                                                scope="col"
                                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                                            >
                                                                {column.name}
                                                            </th>
                                                        );
                                                    if (column.isMulti)
                                                        return (
                                                            <th
                                                                scope="col"
                                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100 w-3/4"
                                                            >
                                                                <ReactSelect
                                                                    options={dataColumns[column.selector].map((dataColumnItem) => { return { value: dataColumnItem, label: dataColumnItem } })}
                                                                    components={SelectCheckOptions}
                                                                    onMenuClose={handleMultipleFilter}
                                                                    data-tipo={column.selector}
                                                                    className="bg-gray-100"
                                                                    onChange={handleFilter}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    hideSelectedOptions={true}
                                                                    name={column.selector}
                                                                    placeholder={column.name}
                                                                    value={selectedDataColumns[column.selector]}
                                                                />
                                                            </th>
                                                        );
                                                    return (
                                                        <th
                                                            scope="col"
                                                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                                        >
                                                            <select
                                                                onChange={handleFilter}
                                                                data-tipo={column.selector}
                                                                className="bg-gray-100"
                                                            >
                                                                <option value="">{column.name}</option>
                                                                {dataColumns[column.selector].map((dataColumnItem) => {
                                                                    return (
                                                                        <option value={dataColumnItem}>
                                                                            {dataColumnItem}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </th>
                                                    );
                                                })
                                                : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableItemsFiltered.length > 0 ? (
                                            tableItemsFiltered.map((row, index) => {
                                                return (
                                                    <tr className="border-b">
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                            {(skip*10)+index + 1}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            <input
                                                                type="checkbox"
                                                                data-id={row._id}
                                                                key={index}
                                                                onChange={handleCheck}
                                                            />
                                                        </td>
                                                        {selectedTemplate.phoneparameter ? (selectedTemplate.phoneparameter.label ? <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {(row['currentMessageStatus'] && row['currentMessageStatus'] != 'pending') ?
                                                                <a href={`/dashboard/chat/${row[selectedTemplate.phoneparameter.label]}`} target="_blank" className="text-blue-500 hover:text-blue-700">Ir al chat</a>
                                                                : 'No hay mensajes'}
                                                        </td> : '') : ''}
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                                <span
                                                                    aria-hidden
                                                                    className={
                                                                        "absolute inset-0 opacity-50 rounded-full"
                                                                            +
                                                                            (row['currentMessageStatus'] != 'pending') ?
                                                                            " bg-green-200" : " bg-orange-200"}>
                                                                </span>
                                                                <p className="relative">
                                                                    {row['currentMessageStatus'] ? row['currentMessageStatus'] : 'No hay mensajes'}
                                                                </p>
                                                            </span>
                                                        </td>

                                                        {
                                                            Object.keys(row).length > 0 ? columns.map((column) => {
                                                                return (
                                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                        {row[column.selector] ? row[column.selector] : 'No data'}
                                                                    </td>
                                                                );
                                                            }) : null
                                                        }
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faSpinner} spinPulse />
                                            </div>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-5">
                    {totalItems > 0 &&
                        <Pagination hideNextButton={true} hidePrevButton={true} count={parseInt((totalItems/10)+1)} variant="outlined" onChange={(e) => setSkip(parseInt(e.target.innerText)-1)} />
                    }
                </div>
            </div>

            <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster backdrop-blur-sm bg-black/30">
                <div className="border border-gray-200 rounded-lg shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                            <p className="text-2xl font-bold"> </p>
                            <div
                                className="modal-close cursor-pointer z-50"
                                onClick={closeModal}
                            >
                                <svg
                                    className="fill-current text-black"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                >
                                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="my-5">
                            <p>{modalMessageBody}</p>
                            <div className="w-full bg-gray-200 h-1 rounded-lg mt-5">
                                <div
                                    className="bg-blue-600 h-3 rounded-lg"
                                    style={{ width: sentItems + "%" }}
                                ></div>
                            </div>
                        </div>
                        <div className="flex justify-end pt-2">
                            <button
                                onClick={closeModal}
                                className="focus:outline-none modal-close px-4 bg-gray-400 p-3 rounded-lg text-white hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button className="focus:outline-none px-4 bg-teal-500 p-3 ml-3 rounded-lg text-white hover:bg-teal-400">
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
