import React, { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';

export function Contact(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [lastMessage, setLastMessage] = useState('');
    const [timestamp, setTimestamp] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [categories, setCategories] = useState('');
    const [labels, setLabels] = useState('');
    const [draft, setDraft] = useState('');
    const [wa_id, setWa_id] = useState('');
    const [selected, setSelected] = useState(props.selected ? props.selected : false);
    const [newMessages, setNewMessages] = useState(0);

    useEffect(() => {
        setName(props.name ? props.name : 'SIN NOMBRE');
        setEmail(props.email);
        setProfilePic(props.profilePic);
        setCategories(props.categories);
        setLabels(props.labels);
        setDraft(props.draft);
        setNewMessages(props.newMessages);
        //slice the last message to the first 20 characters
        try {
            //set the timestamp as date, if the date is today, show the time, if not, show the date
            let timestamp = parseInt(props.lastMessageTimestamp);
            let date = new Date(props.lastMessageTimestamp * 1000);
            let year = date.getFullYear();
            if (year > 2100) {
                date = new Date(props.lastMessageTimestamp);
                year = date.getFullYear();
            }
            let today = new Date();
            if (date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) {
                setTimestamp(date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).split(' ')[0]);
            } else {
                setTimestamp(date.toLocaleDateString());
            }
            setLastMessage(props.lastMessage.length > 20 ? props.lastMessage.slice(0, 20) + '...' : props.lastMessage.slice(0, 20) + '');

        } catch (error) {
            setLastMessage('');
        }
    }, [props.name, props.draft, props.lastMessage, props.newMessages]);

    return (
        <div
            className={`flex flex-row py-4 px-2 justify-center hover:bg-gray-200 items-center border-b-2 cursor-pointer ${!selected ? "" : "bg-gray-200"}`}
        >
            <div className="w-1/4">
                <img
                    src="/profile1.jpg"
                    className="object-cover h-12 w-12 rounded-full"
                    alt=""
                />
            </div>
            <div className="w-full text-left pl-2">
                <div className="font-semibold text-sm">{name}</div>
                <span className="text-gray-500">{draft == '' ? lastMessage : draft}</span>
            </div>
            <div className="w-1/4 text-right">
                <span className={newMessages > 0 ? "text-blue-600 text-xs" : "text-gray-500 text-xs"}>{timestamp}</span><br></br>
                <Badge badgeContent={newMessages} color="primary" sx={{ flexGrow: 1, marginRight: 1 }}>
                </Badge>
            </div>
        </div>
    );
}