import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import Picker, { Emoji, EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';


function PlainMessageNode({ id, data, handleId }) {
    const [characters, setCharacters] = React.useState(4096);
    const [message, setMessage] = React.useState('');

    const [chosenEmoji, setChosenEmoji] = React.useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = React.useState("none");

    const onEmojiClick = (emojiObject, event) => {
        setChosenEmoji(emojiObject.unified);
        setMessage(message + emojiObject.emoji);
        setShowEmojiPicker("none");
    };

    return (
        <>
            <div className="plain-node__header">
                Escriba su mensaje personalizado
            </div>
            <Handle type="target" position={Position.Left} id={handleId} />
            <div className="plain-node__body">
                <textarea
                    className="plain-node__textarea"
                    onChange={(e) => { setCharacters(4096 - e.target.value.length); setMessage(e.target.value) }}
                />
                <button
                    type="button"
                    onClick={() => setShowEmojiPicker(showEmojiPicker == "none" ? "block" : "none")}
                    className="text-white font-bold py-2 px-2 rounded"
                >
                    <InsertEmoticonIcon color='primary' fontSize='medium' />
                </button>
                <div className="" style={{ display: showEmojiPicker, bottom: '-50px' }}>
                    <Picker onEmojiClick={onEmojiClick} width={250} height={400} />
                </div>
                <span>caracteres restantes {characters}</span>
            </div>
        </>
    );
}

export default memo(PlainMessageNode);
