import axios from "axios";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./uselocalstorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [currentConfig, setCurrentConfig] = useLocalStorage("currentConfig", null);
  const navigate = useNavigate();

  const changeCurrentConfig = (config) => {
    setCurrentConfig(config);
  };

  const getCurrentConfig = () => {
    
    return JSON.parse(window.localStorage.getItem("currentConfig"));
  };

  // call this function when you want to authenticate the user
  const login = async (data) => {
    //call your api to authenticate the user
    const url = process.env.REACT_APP_BACKEND_API + "/auth/signin";
    let result = await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    if (result.data.accessToken) {
      setUser(result.data);
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setCurrentConfig(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      currentConfig,
      changeCurrentConfig,
      getCurrentConfig,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
