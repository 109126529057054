import axios from "axios";
import { saveOnStorage } from "../services/localdatabase.service";

const test = () => {
  console.log(process.env.REACT_APP_BACKEND_API);
};

const sendMediaMessage = async (to, message, media, datatype) => {
  const url = process.env.REACT_APP_BACKEND_API + "/messages/sendfilemessage";
  const data = {
    to: to,
    message: {
      type: datatype,
      text: {
        caption: message,
        url: process.env.REACT_APP_SOCKET_URL + "/" + media,
      },
    },
  };
  let result = await axios
    .post(url, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
}

const sendMessage = async (to, message, token) => {
  const url = process.env.REACT_APP_BACKEND_API + "/messages/sendmessage";
  const data = {
    to: to,
    message: {
      type: "text",
      text: {
        body: message,
      },
    },
  };


  let result = await axios
    .post(url, data, {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json',
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

const uploadFileMessage = async (formdata) => {
  const url = process.env.REACT_APP_BACKEND_API + "/chatfiles/upload";
  let result = await axios
    .post(url, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(percent);
        document.getElementById('progress').style.width = percent + '%';
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
}

const loadMessages = async (wa_id, start, token) => {
  const url = process.env.REACT_APP_BACKEND_API + "/messages/" + wa_id + (start ? `?start=${start}` : "");
  const config = {
    url: url,
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    }
  };
  let result = await axios
    .get(config.url, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  if (result) saveOnStorage(result.data);
  else return [];
  return result.data;
};

const findContact = async (token, search) => {
  const url = process.env.REACT_APP_BACKEND_API + "/contacts/find/" + search;
  const config = {
    url: url,
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    }
  }
  let result = await axios
    .get(config.url, config)
    .then((response) => {
      return response;
    }
    )
    .catch((error) => {
      console.log(error);
      return [];
    }
    );
  return result.data;
}

const loadContacts = async (token, start, type) => {
  const url = process.env.REACT_APP_BACKEND_API + "/contacts" + (start ? `?start=${start}` : "?start=0") + (type ? `&type=${type}` : "");
  const config = {
    url: url,
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json',
    }
  }
  let result = await axios
    .get(config.url, config)
    .then((response) => {
      return response;
    }
    )
    .catch((error) => {
      localStorage.removeItem("user");
      window.location.reload();
      return error;
    }
    );
  return result.data;
};

export { test, sendMessage, loadMessages, loadContacts, uploadFileMessage, sendMediaMessage, findContact };
