import "./App.css";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import Login from "./components/Login";
import Chat from "./components/Chat";
import Renovacion from "./components/Renovacion";
import Department from "./components/Department";
import Ejecutivo from "./components/Ejecutivo";
import EjecutivoCobro from "./components/EjecutivoCobro";
import Template from "./components/Template";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { HomeLayout } from "./components/HomeLayout";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { AuthLayout } from "./components/AuthLayout";
import { ProtectedAdminLayout } from "./components/ProtectedAdminLayout";
import { ProtectedAdminRoute } from "./components/ProtectedAdminRoute";
import TemplateCobro from "./components/TemplateCobros";
import CustomTable from "./components/CustomTable";
import Tag from "./components/Tag";
import Subscriptions from "./components/Subscriptions";
import Triggers from "./components/Triggers";
import ContactList from "./components/ContactList";
import ReactFlowBasic from "./components/ReactFlowBasic";
import Survey from "./components/Survey";
import SurveyForm from "./components/SurveyForm";
import Automatizacion from "./components/Automatizacion";
import Automatizaciones from "./components/Automatizaciones";
import CustomExcel from "./components/CustomExcel";

const getUserData = () =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      //if(!user) return reject("error");
      resolve(user);
      //reject("error");
    }, 3000)
  );

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route path="/admin" element={<ProtectedAdminLayout />}>
          <Route
            path="customexcel"
            element={
              <ProtectedAdminRoute>
                <CustomExcel />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="customtable/:tablename"
            element={
              <ProtectedAdminRoute>
                <CustomTable />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="chat/:wa_id"
            element={
              <ProtectedAdminRoute>
                <Chat />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="contacts"
            element={
              <ProtectedRoute>
                <ContactList />
              </ProtectedRoute>
            }
          />
          <Route
            path="reactflowbasic"
            element={
              <ProtectedAdminRoute>
                <ReactFlowBasic />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="survey"
            element={
              <ProtectedAdminRoute>
                <Survey />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="surveyform"
            element={
              <ProtectedAdminRoute>
                <SurveyForm />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="department"
            element={
              <ProtectedAdminRoute>
                <Department />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="subscriptions"
            element={
              <ProtectedAdminRoute>
                <Subscriptions />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="renovacion"
            element={
              <ProtectedAdminRoute>
                <Renovacion />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="ejecutivo"
            element={
              <ProtectedAdminRoute>
                <Ejecutivo />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="ejecutivocobro"
            element={
              <ProtectedAdminRoute>
                <EjecutivoCobro />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="template"
            element={
              <ProtectedAdminRoute>
                <Template />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="templatecobro"
            element={
              <ProtectedAdminRoute>
                <TemplateCobro />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="tag"
            element={
              <ProtectedAdminRoute>
                <Tag />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="triggers"
            element={
              <ProtectedAdminRoute>
                <Triggers />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="renovacion_automatizacion"
            element={
              <ProtectedRoute>
                <Automatizacion />
              </ProtectedRoute>
            }
          />
          <Route
            path="automatizaciones"
            element={
              <ProtectedRoute>
                <Automatizaciones />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/dashboard" element={<ProtectedLayout />}>
          <Route
            path="chat/:wa_id"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="renovacion"
            element={
              <ProtectedRoute>
                <Renovacion />
              </ProtectedRoute>
            }
          />
          <Route
            path="customtable/:tablename"
            element={
              <ProtectedRoute>
                <CustomTable />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
    </>
  )
);
