import React, { useEffect, useState } from "react";
import TemplateMessage from "./TemplateMessage";
import MediaMessage from "./MediaMessage";
import PayloadMessage from "./PayloadMessage";
import StatusMessage from "./StatusMessage";
import { useAuth } from "../services/auth.service";
import SurveyMessage from "./SurveyMessage";

function Message(props) {
  const [message, setMessage] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [error, setError] = useState(false);
  const { user } = useAuth();
  const surveyId = "65cfa291d2cf9544d0ee5667";
  const [survey, setSurvey] = React.useState();

  useEffect(() => {
    const newMessage =
      props.message?.entry[0]?.changes[0]?.value?.messages[0] || null;
    const lastStatus = props.message?.entry[0]?.changes[0]?.value?.statuses?.slice(-1)[0] || null;
    setStatuses(lastStatus || null);
    setMessage(newMessage);
  }, [props.message]);

  useEffect(() => {
    const lastStatus = props.message?.entry[0]?.changes[0]?.value?.statuses?.slice(-1)[0] || null;
    setStatuses(lastStatus || null);
  }, [JSON.stringify(props.message?.entry[0]?.changes[0]?.value?.statuses)]);

  useEffect(() => {
    if (message?.type === "text") {
      if (message?.text?.body?.name) {
        console.log("Template message");
      } else {
        if (message?.text.body.text) {
          console.log("Text message with subtext")
        }
        console.log("Text message");
      }
    } else {
      console.log("Media message");
    }
  }, [message]);






  const renderMessage = () => {

    if (!message || !message.from || !message.timestamp) {
      return (
        <div className="flex justify-end mb-4">
          <div
            className="mr-2 py-4 px-4 bg-blue-400 rounded-bl-2xl rounded-tl-2xl rounded-br-xl text-white align-right min-w-[18%] max-w-[60%]"
            style={{ position: "relative" }}
          >
            This message is not supported
            <span className="time-message absolute">
              {message
                ? new Date(message.timestamp * 1000).toLocaleTimeString()
                : ""}
            </span>
          </div>
          <img
            src="/profile1.jpg"
            className="object-cover h-8 w-8 rounded-full"
            alt=""
          />
        </div>
      );
    } else if (message?.from === process.env.REACT_APP_PHONE_NUMBER) {
      return (
        <div className={message.custom_status ? "text-center text-sm mb-5" : "flex justify-end mb-4"}>
          <div
            className={message.custom_status ? "rounded-lg bg-gray-100 py-2" : "mr-2 py-4 px-4 bg-blue-100 rounded-bl-2xl rounded-tl-2xl rounded-br-xl align-right min-w-[18%] max-w-[60%]"}
            style={{ position: "relative" }}
          >
            {message?.text?.body?.name ? (
              <TemplateMessage message={message} sendTemplateMessage={props.sendTemplateMessage} cancelTemplateMessage={props.cancelTemplateMessage} />
            ) : message?.text?.body ? (
              typeof message.text.body === "string" ? (
                message.text.body) : (
                message.text.body.type === "text" ? (
                  message.text.body.text.body
                ) : (
                  <MediaMessage type={message.text.body.type} media={message.text.body} isSender={true} />
                )
              )
            ) : (
              "This message is not supported"
            )}
            <span className="time-message absolute">
              <span>
                {message
                  ? new Date(message.timestamp * 1000).toLocaleTimeString()
                  : ""}
                {
                  message.custom_status ? "" : <StatusMessage statuses={statuses} />
                }
              </span>
            </span>
          </div>
          {message.custom_status ? "" : <img
            src="/icon.png"
            className="object-cover h-8 w-8 rounded-full"
            alt=""
          />}

        </div>
      );
    } else if (message?.interactive?.nfm_reply) {
      return (
        <div className="flex justify-end mb-4">
          <div
            className="mr-2 py-4 px-4 bg-blue-400 rounded-bl-2xl rounded-tl-2xl rounded-br-xl text-white align-right min-w-[18%] max-w-[60%]"
            style={{ position: "relative" }}>
            <SurveyMessage responseJson={message.interactive.nfm_reply.response_json} />
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="flex justify-start mb-4">
          <img
            src="/profile1.jpg"
            className="object-cover h-8 w-8 rounded-full"
            alt=""
          />
          <div
            className="ml-2 py-4 px-4 bg-gray-100 rounded-br-2xl rounded-tr-2xl rounded-bl-xl align-right min-w-[18%] max-w-[60%]"
            style={{ position: "relative" }}
          >
            {message?.type === "text" ? (
              message.text.body
            ) : (
              <MediaMessage type={message?.type} media={message} isSender={false} />
            )}
            {message?.type === "button" ? (
              <PayloadMessage message={message} />
            ) : ""}
            {message?.type === "reaction" ? (
              "Reaccionó con " + message.reaction.emoji
            ) : ""}
            <span className="time-message-left absolute">
              {message
                ? new Date(message.timestamp * 1000).toLocaleTimeString()
                : ""}
            </span>
          </div>
        </div>
      );
    }
  };

  const handleCatch = () => {
    setError(true);
  };

  return <>{renderMessage()}</>;
}

export default Message;
