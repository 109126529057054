import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import { useAuth } from "../services/auth.service";
import axios from "axios";


const dragHandleStyle = {
    display: 'inline-block',
    width: 25,
    height: 2,
    backgroundColor: '#ddd',
    cursor: 'grab',
};

function ListMessageNode({ id, data, handleId }) {
    const { setNodes, setEdges } = useReactFlow();
    const store = useStoreApi();
    const [valueSelect, setValueSelect] = React.useState('');
    const { authTokens } = useAuth();
    const [itemId, setItemId] = React.useState(1);
    const [categoryId, setCategoryId] = React.useState(1);
    const [options, setOptions] = React.useState([{
        title: 'Seccion',
        id: categoryId,
        rows: [{
            id: itemId,
            title: 'Item Title',
            description: 'Item Description',
        }]
    }]);

    const addNewCategory = () => {
        //check to not exceed max 5 categories
        if (options.length >= 5) {
            return;
        }
        const newCategory = {
            title: 'Categoria',
            id: categoryId + 1,
            rows: [{
                id: options.length + 1,
                title: 'Titulo',
                description: 'Descripcion',
            }]
        };
        setCategoryId((categoryId) => categoryId + 1);
        setOptions((options) => options.concat(newCategory));
    }

    const addNewItem = (category) => {
        //check if not exceed max 5 items
        if (category.rows.length >= 5) {
            return;
        }
        const newItem = {
            id: itemId + 1,
            title: 'Opcion',
            description: 'Descripcion',
        };
        setItemId((itemId) => itemId + 1);
        category.rows.push(newItem);
        //update the category in options
        const index = options.findIndex((cat) => cat.id === category.id);
        options[index] = category;
        setOptions((options) => [...options]);
    }

    const removeItem = (category, item) => {
        const index = category.rows.findIndex((row) => row.id === item.id);
        category.rows.splice(index, 1);
        //update the category in options
        const indexCategory = options.findIndex((cat) => cat.id === category.id);
        options[indexCategory] = category;
        setOptions((options) => [...options]);
    }

    return (
        <>
            <div class="list-message-node">
                <div className="text-sm custom-drag-handle cursor-move">
                    Mensaje de Lista
                </div>
                <Handle type="target" position={Position.Left} id={handleId} />
                <h1 style={{ textAlign: 'center' }} contentEditable={true}>Titulo de la lista</h1>
                {options.map((category, catkey) => (
                    <div key={category.catkey} className='mb-3'>
                        <small contentEditable={true}>{category.title}</small>
                        {category.rows.map((row, rowkey) => (
                            <div key={row.rowkey}>
                                <button onClick={(e) => removeItem(category, row)} type="button" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20">
                                    <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75">
                                        <path d="M4 4l6 6m0-6l-6 6" />
                                    </svg>
                                    <span class="absolute -inset-1"></span>
                                </button>
                                <h3 contentEditable={true}>{row.title}</h3>
                                <small><p contentEditable={true}>{row.description}</p></small>
                                <div class="handler-for-list">
                                    <Handle type="source" position={Position.Right} id={handleId} />
                                </div>
                            </div>
                        ))}
                        <button style={{ backgroundColor: 'green' }} onClick={(e) => addNewItem(category)} >Agregar opcion</button>
                    </div>
                ))}
                <button style={{ backgroundColor: 'green' }} onClick={(e) => addNewCategory()} >Agregar seccion</button>
            </div>
        </>
    );
}

export default memo(ListMessageNode);
