import { Navigate } from "react-router-dom";
import { useAuth } from "../services/auth.service";

export const ProtectedAdminRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }else if(user.roles.includes("ROLE_ADMIN")){
    //check the user role
    return children;
  }
};