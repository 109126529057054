import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { type } from '@testing-library/user-event/dist/type';


const settings = ['Profile', 'Delete Cache', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElViews, setAnchorElViews] = React.useState(null);
  const { user, logout, currentConfig, changeCurrentConfig, getCurrentConfig } = useAuth();
  const [pages, setPages] = React.useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    try {
      if (user) {
        if (user.roles.includes("ROLE_ADMIN")) {
          setPages(['chat', 'department', 'renovacion', 'ejecutivo', 'template', 'tag', 'customtable/cobros', 'customtable/cotizacion', 'customtable/cotizacionincendio', 'subscriptions', 'triggers', 'survey', 'renovacion_automatizacion', 'automatizaciones', 'customexcel']);
        } else if (user.roles.includes("ROLE_USER")) {
          setPages([...new Set(user.views.map((view) => view.value))]);
        }
      }
    } catch (error) {
      //delete the user from localstorage
      localStorage.removeItem('user');
      //reload the page
      window.location.reload();
    }

  }, [user]);

  const handleOpenNavMenu = (event) => {
    //check if the page end in /chat
    if (window.location.pathname.endsWith("/chat")) {
      //back to contacts list
      changeCurrentConfig({ currentPage: "contacts", currentContact: null });
      //navigate to chat
      navigate('/dashboard/chat');
    } else {
      setAnchorElNav(event.currentTarget);
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenViewsMenu = (event) => {
    setAnchorElViews(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    //check if page is string
    if (typeof page === 'string') {
      const path = user.roles.includes("ROLE_ADMIN") ? '/admin/' : '/dashboard/';
      navigate(path + page)
      setAnchorElNav(null);
    }
    else {
      setAnchorElViews(null);
    }
  };

  const handleCloseUserMenu = (setting) => {
    if (setting === 'Logout') {
      logout();
    }
    else if (setting === 'Profile') {
      navigate('/dashboard/profile')
    }
    else if (setting === 'Delete Cache') {
      localStorage.clear();
      window.location.reload();
    }
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', p: 0 } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <ArrowBackIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} tablename={page} onClick={() => handleCloseNavMenu(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Roboto',
              fontWeight: 200,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src="https://firebasestorage.googleapis.com/v0/b/segenesis-cotizador.appspot.com/o/notificaciones%2FGenesisLOGO%20vida%20y%20salud%20(1).png?alt=media&token=2e7ce64b-a316-4579-b3de-37468e2fa470" alt="logo" width="50" height="50" />

          </Typography>
          <Button
            id="basic-button-chat"
            onClick={() => handleCloseNavMenu("chat")}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            CHAT
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              id="basic-button"
              open={Boolean(anchorElViews)}
              aria-haspopup="true"
              aria-aria-expanded={Boolean(anchorElViews) ? 'true' : undefined}
              onClick={handleOpenViewsMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Menu
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElViews}
              open={Boolean(anchorElViews)}
              onClose={handleCloseNavMenu}
              aria-labelledby="basic-button"
            >

              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => handleCloseNavMenu(page)}
                >
                  {page}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;