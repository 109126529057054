import React, { useEffect, useState, useRef } from 'react';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import Picker, { Emoji, EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import { SpeedDial } from '@mui/material';
import { SpeedDialAction } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { SpeedDialIcon } from '@mui/material';
import { icon } from '@fortawesome/fontawesome-svg-core';

export default function MessageInput(props) {
    const [currentValue, setCurrentValue] = useState(props.inputValue);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState("none");
    const [chosenFile, setChosenFile] = useState(null);
    const inputRef = useRef(null);

    const onEmojiClick = (emojiObject, event) => {
        setChosenEmoji(emojiObject.unified);
        setCurrentValue(currentValue + emojiObject.emoji);
        setShowEmojiPicker("none");
    };

    const onHoverAction = (e, action) => {
        if (document.getElementById('file') == null) return;
        if (action.datatype == 'Archivo') {
            document.getElementById('file').setAttribute('accept', '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.zip,.rar,.7z,.mp3,.wav,.ogg,.mp4,.avi,.mkv,.mov,.wmv,.flv,.webm,.jpg,.jpeg,.png,.gif,.svg');
            //add data-type to the file input
            document.getElementById('file').setAttribute('data-type', 'file');
        }
        else if (action.datatype == 'Imagen') {
            document.getElementById('file').setAttribute('accept', '.jpg,.jpeg,.png,.gif,.svg');
            //add data-type to the file input
            document.getElementById('file').setAttribute('data-type', 'image');
        }
        else if (action.datatype == 'Video') {
            document.getElementById('file').setAttribute('accept', '.mp4,.avi,.mkv,.mov,.wmv,.flv,.webm');
            //add data-type to the file input
            document.getElementById('file').setAttribute('data-type', 'video');
        }
    }

    const onChangeValue = (e) => {
        setCurrentValue(e.target.value);
        props.setInput(e);
    }

    const onEnterPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            onSubmit(e);
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        inputRef && inputRef.current.click();
    }

    const actions = [
        { icon: <InsertDriveFileIcon />, name: 'Archivo', onClick: (e) => onHoverAction(e, { datatype: 'Archivo' }) },
        { icon: <ImageIcon />, name: 'Imagen', onClick: (e) => onHoverAction(e, { datatype: 'Imagen' }) },
        { icon: <VideoLibraryIcon />, name: 'Video', onClick: (e) => onHoverAction(e, { datatype: 'Video' }) },
    ];

    const onSubmit = async (e) => {
        e.preventDefault();
        await props.onSubmit(e);
        setCurrentValue('');
    }

    return (
        <>
            <form encType='multipart/form-data'>
                <input
                    type="file"
                    id="file"
                    ref={inputRef}
                    className="hidden"
                    data-type="file"
                    onChange={props.onFileUpload}
                />
            </form>
            <form onSubmit={onSubmit} id="formtext">
                <div className="flex flex-row">
                    <button
                        type="button"
                        onClick={() => setShowEmojiPicker(showEmojiPicker == "none" ? "block" : "none")}
                        className="text-white font-bold py-2 px-2 rounded"
                    >
                        <InsertEmoticonIcon color='primary' fontSize='medium' />
                    </button>
                    <div className="absolute bottom-20" style={{ display: showEmojiPicker }}>
                        <Picker onEmojiClick={onEmojiClick} />
                    </div>
                    <div
                        className="text-white font-bold py-2 px-2 rounded relative w-20"
                    >

                        <SpeedDial
                            FabProps={{ size: 'small', style: { backgroundColor: '#ffffff', color: '#000000', boxShadow: 'none' } }}
                            ariaLabel="SpeedDial basic example"
                            sx={{ position: 'absolute', bottom: 8, right: 10 }}
                            icon={<AttachFileIcon fontSize='medium' color='primary' />}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={handleClick}
                                    onMouseEnter={action.onClick}
                                    datatype={action.name}
                                />
                            ))}
                        </SpeedDial>
                    </div>

                    <textarea
                        value={currentValue}
                        onChange={(e) => onChangeValue(e)}
                        className="w-full bg-gray-100 py-5 px-3 outline-none placeholder-gray-500 text-gray-500 font-light text-sm"
                        type="text"
                        placeholder="Escriba su mensaje aqui...."
                        rows={1}
                        onKeyDown={(e) => onEnterPress(e)}
                    />
                    <button type="submit" className="text-white font-bold py-2 px-4 rounded"><SendIcon color='primary' fontSize='medium' /></button>
                </div>
            </form>
        </>
    )
}