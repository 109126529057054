import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../services/auth.service";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { isMobile } from "react-device-detect";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      {!isMobile && <ResponsiveAppBar />}
      {outlet}
    </div>
  );
};
