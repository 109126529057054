import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';


const dragHandleStyle = {
    top: '24px',
    right: '-15px',
    width: '6px',
    height: '10px',
    borderRadius: '2px',
    backgroundColor: '#778899',
};


function ButtonMessageNode(props) {
    const store = useStoreApi();
    const [buttonConnected, setButtonConnected] = React.useState(0);
    const { setNodes, setEdges } = useReactFlow();
    const [nodeId, setNodeId] = React.useState(props.id);
    const [interactive, setInteractive] = React.useState({
        "type": "button",
        "header": {
            "type": "text",
            "text": "Header Message"
        },
        "body": {
            "text": "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        "footer": {
            "text": "Footer"
        },
        "action": {
            "buttons": [
                {
                    "type": "reply",
                    "reply": {
                        "id": "unique-id-123",
                        "title": "Yes"
                    }
                },
                {
                    "type": "reply",
                    "reply": {
                        "id": "unique-id-456",
                        "title": "No"
                    }
                },
                {
                    "type": "reply",
                    "reply": {
                        "id": "unique-id-789",
                        "title": "Maybe"
                    }
                }
            ]
        }
    });
    const [handlers, setHandlers] = React.useState([
        {
            id: "unique-id-123",
            title: "Yes",
        },
        {
            "id": "unique-id-456",
            "title": "No"
        },
        {
            "id": "unique-id-789",
            "title": "Maybe"
        }
    ]);



    const onUpdateInput = (evt, elementId) => {
        //check if the element is button
        if (evt.target.tagName === 'SPAN') {

            const index = interactive.action.buttons.findIndex((button) => button.reply.id === elementId);
            interactive.action.buttons[index].reply.title = evt.target.innerHTML;
            setInteractive((interactive) => ({ ...interactive }));
            //connect the nodeId to a new node
            const { nodeInternals } = store.getState();
            const nodeSelectIs = Array.from(nodeInternals.values()).filter((node) => {
                if (node.id === nodeId) {
                    return node;
                }
            }
            )[0];
            const currentId = parseInt(nodeInternals.size) + 1;
            const id = `${nodeId}-${currentId}`;
            const newNode = {
                id: currentId + '',
                type: 'custom',
                data: {
                    selects: {
                        'handle-1': 'smoothstep',
                    },
                },
                style: {
                    background: '#fefefe',
                },
                dragHandle: '.custom-drag-handle',
                leftHandler: true,
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                position: {
                    x: nodeSelectIs.position.x + 400,
                    y: nodeSelectIs.position.y + 100 + (buttonConnected * 150),
                },
                origin: [0.5, 0.0],
            };
            setNodes((nds) => nds.concat(newNode));
            //append the new node to the selected node
            setEdges((eds) => eds.concat({ id: id, source: nodeId, sourceHandle: handlers[buttonConnected].id, target: newNode.id }));
            setButtonConnected((buttonConnected) => buttonConnected + 1);
        } else if (elementId === 'header') {
            interactive.header.text = evt.target.innerText;
            setInteractive((interactive) => ({ ...interactive }));
        } else if (elementId === 'body') {
            interactive.body.text = evt.target.innerText;
            setInteractive((interactive) => ({ ...interactive }));
        } else if (elementId === 'footer') {
            interactive.footer.text = evt.target.innerText;
            setInteractive((interactive) => ({ ...interactive }));
        }
    }

    const topStart = 150;

    return (
        <>
            <div className="button-message-node p-2 shadow max-w-[300px]">
                <div className="text-sm custom-drag-handle cursor-move">
                    Mensaje con botones
                </div>
                <Handle type="target" position={Position.Left} id={props.handleId} />
                <h1 onBlur={(e) => onUpdateInput(e, 'header')} className="text-xs cursor-text my-2 text-gray-400" contentEditable={true}>{interactive.header.text}</h1>
                <p onBlur={(e) => onUpdateInput(e, 'body')} className="text-xs cursor-text my-2" contentEditable={true}>{interactive.body.text}</p>
                <p onBlur={(e) => onUpdateInput(e, 'footer')} className="text-xs cursor-text my-2 text-gray-400" contentEditable={true}>{interactive.footer.text}</p>
                {interactive.action.buttons.map((button) => (
                    <button className="cursor-text text-blue-500 shadow text-xs px-4 py-1 my-1 rounded block w-full" ><span onBlur={(e) => onUpdateInput(e, button.reply.id)} contentEditable={true}>{button.reply.title}</span></button>
                ))}
                {
                    handlers.map((handler, idx) => (
                        <Handle
                            type="source"
                            position={Position.Right}
                            id={handler.id}
                            style={{
                                top: `${topStart + (idx * 30)}px`,
                                right: '5px',
                                width: '6px',
                                height: '10px',
                                borderRadius: '2px',
                                backgroundColor: '#778899',
                            }}
                        />
                    ))
                }
            </div>
        </>
    );
}

export default memo(ButtonMessageNode);
