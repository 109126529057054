import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../services/auth.service';

export default function Login() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    
    const handleSubmit = async (e) => {
        //check the submit for errors
        if (!username) {
            setError('Email is required');
            return;
        }
        if (!password) {
            setError('Password is required');
            return;
        }
        e.preventDefault();
        login({username:username,password:password}).then(() => {
            navigate('/dashboard/chat');
        }).catch((error) => {
            setError("Invalid username or password");
        });
    };
    
    return (
        <div className="flex items-center justify-center h-screen">
        <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center justify-center 4/4 md:w-1/4 p-8 bg-white rounded shadow"
        >
            <h1 className="text-2xl font-bold mb-6">Login</h1>
            <div className="flex flex-col w-full mb-4">
            <label htmlFor="username" className='text-left'>Username</label>
            <input
                type="text"
                name="username"
                id="username"
                className="border rounded p-2"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            </div>
            <div className="flex flex-col w-full mb-4">
            <label htmlFor="password" className='text-left'>Password</label>
            <input
                type="password"
                name="password"
                id="password"
                className="border rounded p-2"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
            Login
            </button>
        </form>
        </div>
    );
}

