//create a new component for the chat module
import React, {
  useState,
  useEffect,
} from "react";
import Messages from "./Messages";
import SidebarInfo from "./SidebarInfo";
import MessageInput from "./MessageInput";
import {
  getContacts,
  getMessages,
  updateContact,
  saveSentMessage,
  searchContact,
} from "../services/localdatabase.service";
import { sendMessage, uploadFileMessage, sendMediaMessage, findContact } from "../services/wapib.service";
import { socket } from "../services/Socket";
import { useAuth } from "../services/auth.service";
import Contacts from "./Contacts";
import ResponsiveChatAppBar from "./ResponsiveChatAppBar";
import { isMobile } from "react-device-detect";
import Push from "push.js";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from '@mui/material/Chip';
import { useParams } from "react-router-dom";

export default function Chat() {
  const [currentContact, setCurrentContact] = useState({
    "_id": [
      [
        [
          "50769237925"
        ]
      ]
    ],
    "contact": [
      [
        [
          {
            "profile": {
              "name": "Chat"
            },
            "wa_id": ""
          }
        ]
      ]
    ]
  });
  const [mobileTimer, setMobileTimer] = useState(undefined);
  const [contacts, setContacts] = useState([]);
  const [sentContacts, setSentContacts] = useState([]);
  const [unreadContacts, setUnreadContacts] = useState([]);
  const [automatedContacts, setAutomatedContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState(null);
  const { user, logout, currentConfig, changeCurrentConfig, getCurrentConfig } = useAuth();
  const [currentPage, setCurrentPage] = useState("contacts");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [uploadDetails, setUploadDetails] = useState(null);
  const [datatype, setDatatype] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const params = useParams();
  const [currentContactID, setCurrentContactID] = useState(params.wa_id);

  const handleNewMessage = (data) => {
    try {

      //check if message or status
      if (data.entry[0].changes[0].value.statuses) {
        if (
          currentContact &&
          currentContact.Celular ===
          data.entry[0].changes[0].value.statuses[0].recipient_id
        ) {
          let messageStatus = data.entry[0].changes[0].value.statuses[0];
          let messageStatusExists = false;
          messageStatusExists = messages.filter((messageStatusFromState) => {
            try {
              if (messageStatusFromState.entry[0].changes[0].value.messages[0].id ===
                messageStatus.id) {
                return true;
              } else {
                return false;
              }
            } catch (error) {
              return false;
            }
          });
          if (messageStatusExists) {
            messageStatusExists[0].entry[0].changes[0].value.statuses = messageStatusExists[0].entry[0].changes[0].value.statuses ? [...messageStatusExists[0].entry[0].changes[0].value.statuses, messageStatus] : [messageStatus];
            let newStatusMessages = messages.filter((messageStatusFromState) => {
              try {
                if (messageStatusFromState.entry[0].changes[0].value.messages[0].id !==
                  messageStatus.id) {
                  return true;
                } else {
                  return false;
                }
              } catch (error) {
                return false;
              }
            }
            );
            setMessages(sortMessages([...newStatusMessages, messageStatusExists[0]]));
          }
        }
      } else {

        //check if the wa_id is the same as the current contact
        if (
          currentContact &&
          currentContact.Celular ===
          data.entry[0].changes[0].value.contacts[0].wa_id
        ) {
          //check if the message not exists on the messages array
          let message = data.entry[0].changes[0].value.messages[0];
          let messageExists = messages.filter((messageFromState) => {
            try {
              return (
                messageFromState.entry[0].changes[0].value.messages[0].id ===
                message.id
              );
            } catch (error) {
              return false;
            }
          });
          if (messageExists) {
            if (messageExists.length === 0) {
              //add the message on first to the messages array
              setMessages([...messages, data]);
            } else {
              //replaces the message with the new one
              let newMessages = messages.filter((messageFromState) => {
                try {
                  return (
                    messageFromState.entry[0].changes[0].value.messages[0].id !==
                    message.id
                  );
                } catch (error) {
                  return false;
                }
              }
              );
              setMessages(sortMessages([...newMessages, data]));
            }
          } else {
            setMessages(sortMessages([...messages, data]));
          }
          let messagesContainer = document.getElementById("messagesContainer");
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        } else if (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_USER")) {
          //check if the contact exists
          let contactExists = contacts.filter((contact) => {
            try {
              return (
                contact.Celular ===
                data.entry[0].changes[0].value.contacts[0].wa_id
              );
            } catch (error) {
              return false;
            }
          });
          if (contactExists.length > 0) {
            //delete the contact
            let orderedContacts = contacts.filter(
              (contact) => {
                try {
                  return contact.Celular !==
                    data.entry[0].changes[0].value.contacts[0].wa_id
                } catch (error) {
                  return false;
                }
              }
            );
            //change the lastMessage
            contactExists[0].lastMessage = data.entry[0].changes[0].value.messages[0];
            contactExists[0].lastMessageTimestamp = data.entry[0].changes[0].value.messages[0].timestamp;


            setContacts([contactExists[0], ...orderedContacts]);
          } else {
            //add the contact
            let newContact = {
              _id: data.entry[0].changes[0].value.contacts[0].wa_id,
              NombreCompleto: data.entry[0].changes[0].value.contacts[0].profile.name,
              Celular: data.entry[0].changes[0].value.contacts[0].wa_id,
              newMessages: 1,
              sentMessage: false,
              lastMessage: data.entry[0].changes[0].value.messages[0],
            };
            //put the new contact on the top
            let newContacts = contacts;

            setContacts([newContact, ...newContacts]);
          }
        }
      }
    } catch (error) {
      console.log(error + "data is metadata not messages");
    }
  };

  const onSelectTemplate = (e, template) => {
    e.preventDefault();
    //delete variables localstorage
    localStorage.removeItem('variables');
    setSelectedTemplate(template);
    let draftTemplate = {
      "_id": "643ea3911f2627d1316ds214f",
      "entry": [
        {
          "changes": [
            {
              "field": "messages",
              "value": {
                "contacts": [
                  {
                    "profile": {
                      "name": "GENESIS SEGUROS"
                    },
                    "wa_id": "50763788349"
                  }
                ],
                "messages": [
                  {
                    "from": "50766289358",
                    "id": "wamid.HBgLNTA3NjM3ODgdzNDkVAgARGBI2RjEwRTk0MTRCNDg5NjM2REEA",
                    "text": {
                      "body": {
                        "name": template.name,
                        "editable": true,
                        "language": {
                          "policy": "deterministic",
                          "code": "es"
                        },
                        "components": [
                          {
                            "type": "header",
                            "parameters": [
                              {
                                "type": "video",
                                "video": {
                                  "link": "https://firebasestorage.googleapis.com/v0/b/segenesis-cotizador.appspot.com/o/notificaciones%2Fcumpleanos2022.mp4?alt=media&token=222ca809-c827-4e81-a888-e9fc32785c04"
                                }
                              }
                            ]
                          },
                          {
                            "type": "body",
                            "parameters": [
                              {
                                "type": "text",
                                "text": "input text"
                              }
                            ]
                          }
                        ],
                        "_": "template"
                      }
                    },
                    "timestamp": (new Date().getTime() / 1000) + "",
                    "type": "text"
                  }
                ],
                "messaging_product": "whatsapp",
                "metadata": {
                  "display_phone_number": "50766289358",
                  "phone_number_id": null
                }
              }
            }
          ],
          "id": "105520548863497"
        }
      ],
      "object": "whatsapp_business_account"
    };

    //append the template to the messages
    let newMessagesAddTemplate = [...messages, { _id: 0, entry: draftTemplate.entry }];
    setMessages(newMessagesAddTemplate);
  }

  const cancelTemplateMessage = (e) => {
    e.preventDefault();
    //eliminate the last message on messages
    let newMessages = messages;
    newMessages.pop();
    setMessages(newMessages);
    setSelectedTemplate(null);
  }


  const sendTemplateMessage = async (e) => {
    e.preventDefault();
    if (sendingMessage) return;
    if (!selectedTemplate) {
      setError("The template is empty");
      return;
    }
    try {
      //load variables from localstorage
      let variables = localStorage.getItem('variables');
      fetch(process.env.REACT_APP_BACKEND_API + "/messages/sendtemplatemessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user.accessToken,
        },
        body: JSON.stringify({
          to: currentContact.Celular,
          templateID: selectedTemplate.name,
          parameters: variables ? JSON.parse(variables) : [],
          header: {
            image: selectedTemplate.image !== "" ? selectedTemplate.image : null,
            video: selectedTemplate.video !== "" ? selectedTemplate.video : null,
          },
          buttonParameters: []
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong ...");
          }
        }
        )
        .then((data) => {
          data.contacts = [{ wa_id: currentContact.Celular, profile: { name: currentContact.NombreCompleto } }];
          if (data.entry)
            setNewMessage(data);

          // let newMessage = saveSentMessage({data:data}, currentContact.draft);
          // //eliminate the last message first
          // let newMessages = messages;
          // newMessages.pop();
          // newMessages.push({ _id: 0, entry: newMessage.entry });
          // setMessages(newMessages);
          // setCurrentContact({ ...currentContact, draft: selectedTemplate.name });
          // setSelectedTemplate(null);
        }
        )
        .catch((error) => {
          console.log(error);
          //drop the last message
          let newMessages = messages;
          newMessages.pop();
          setMessages(newMessages);
        });
    } catch (error) {
      setError("Error sending the message");
    }
  }

  useEffect(() => {
    if (currentContact) {
      //check if the draft property exists and is not empty
      if (currentContact.draft && currentContact.draft.trim() !== "") {
        updateContact(
          currentContact.wa_id,
          currentContact.lastMessage,
          currentContact.draft
        );
      }
    }
    setLoading(false);
  }, [currentContact, contacts]);

  useEffect(() => {
    if (newMessage != null) handleNewMessage(newMessage);
  }, [newMessage]);

  useEffect(() => {
    //scroll the container to bottom
    if (document.getElementById("messagesContainer")) {
      let messagesContainer = document.getElementById("messagesContainer");
      messagesContainer.childNodes[0].scrollTop = messagesContainer.childNodes[0].scrollHeight;
    }
    if (messages.length > 0) {
      document.getElementById("messagesContainer").classList.remove("hidden");
      document.getElementById("contactsContainer").classList.add("hidden");
      changeCurrentConfig({ currentPage: "messages", currentContact: currentContact });
    }
  }, [messages]);

  const [error, setError] = useState("");

  const [search, setSearch] = useState("");


  useEffect(() => {
    //loads the currentContact message on the first render
    if (currentContactID) {
      let contact = contacts.find((contact) => contact.Celular === currentContactID);
      if (contact) {
        setCurrentContact(contact);
        loadContactMessages(0, contact.inboxtype);
        setCurrentContactID(null);
      }
    }
  }, [contacts]);

  const firstData = async () => {
    setError("");
    //check if the messages on localstorage exists
    let newContacts = await getContacts(user.accessToken);
    if (currentContactID) {
      let currentPageContact = await findContact(user.accessToken, currentContactID);
      //check if currentContactId exists on the contacts
      let contactExists = newContacts.filter((contact) => {
        try {
          return contact.Celular === currentContactID;
        } catch (error) {
          return false;
        }
      });
      if (contactExists.length > 0) {
        //put on first
        newContacts = newContacts.filter((contact) => {
          try {
            return contact.Celular !== currentContactID;
          } catch (error) {
            return false;
          }
        });
        newContacts.unshift(contactExists[0]);
      } else {

        //add to newContacts to the first
        if (currentPageContact.length > 0) {
          newContacts.unshift(currentPageContact[0]);
        }
      }
    }
    if (newContacts.length > 0) {
      setContacts(newContacts);
      changeCurrentConfig({ currentPage: "contacts", currentContact: null });
    }
    let newSentContacts = await getContacts(user.accessToken, "0", "sent");
    if (newSentContacts.length > 0) {
      setSentContacts(newSentContacts);
    }
    let newUnreadContacts = await getContacts(user.accessToken, "0", "unread");
    if (newUnreadContacts.length > 0) {
      setUnreadContacts(newUnreadContacts);
    }
    let newAutomatedContacts = await getContacts(user.accessToken, "0", "automated");
    if (newAutomatedContacts.length > 0) {
      setAutomatedContacts(newAutomatedContacts);
    }
  };

  const handleSubmit = async (submitEvent) => {
    //check the submit for errors
    submitEvent.preventDefault();
    if (sendingMessage) return;
    let inputValueIs = submitEvent.target.value ? submitEvent.target.value : (submitEvent.target.childNodes[0].childNodes[3] ? submitEvent.target.childNodes[0].childNodes[3].value : "");
    if (submitEvent.target.value && submitEvent.target.value.trim() === "") {
      setError("The message is empty");
      return;
    } else if (submitEvent.target.childNodes[0].childNodes[3] && submitEvent.target.childNodes[0].childNodes[3].value === "") {
      setError("The message is empty");
      return;
    }
    try {
      if (datatype) {
        let result = await sendMediaMessage(
          currentContact.Celular,
          inputValueIs,
          uploadDetails.path,
          datatype
        );
        let newMessage = saveSentMessage(result, currentContact.draft);
        setMessages(sortMessages([...messages, { _id: 0, entry: newMessage.entry }]));
        setCurrentContact({ ...currentContact, draft: "" });
        setUploadDetails(null);
        setDatatype(null);
        setSendingMessage(false);
        inputValueIs = "";
        return;
      }
      let newMessageResult = await sendMessage(
        currentContact.Celular,
        inputValue,
        user.accessToken
      );
      let newMessage = saveSentMessage(newMessageResult, inputValue);
      setMessages([...messages, { _id: 0, entry: newMessage.entry }]);
      setCurrentContact({ ...currentContact, draft: "" });
      setInputValue("");
    } catch (error) {
      setError("Error sending the message");
    }
    setSendingMessage(false);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (sendingMessage) return;
    if (e.target.files.length === 0) {
      setError("The file is empty");
      return;
    }
    try {
      let form = e.target;
      const formData = new FormData();
      formData.append("file", form.files[0]);
      setUploadDetails({ filename: form.files[0].name, size: form.files[0].size });
      let newMessageResult = await uploadFileMessage(formData);
      setUploadDetails(newMessageResult.data);
      setDatatype(e.target.dataset.type);
    } catch (error) {
      setError("Error sending the message");
    }
    setSendingMessage(false);
  };

  const onFileUploadCancel = () => {
    setUploadDetails(null);
    setDatatype(null);
  }

  const onFileUploadClick = () => {
    console.log(uploadDetails.path);
  }

  useEffect(() => {
    async function fetchContacts() {
      const searchContacts = await getContacts(user.accessToken);

      let filteredContacts = searchContacts.filter((contact) => {
        try {
          return contact.asegurado.NombreCompleto.toLowerCase().includes(search.toLowerCase()) || contact.asegurado.Celular.toLowerCase().includes(search.toLowerCase()) || contact.asegurado.etiquetas.map((etiqueta) => etiqueta.label.toLowerCase()).includes(search.toLowerCase());
        } catch (error) {
          return false;
        }
      });
      if (filteredContacts.length > 0) {
        setContacts(filteredContacts);
      }
      else {
        const apiContacts = await searchContact(user.accessToken, search);
        if (apiContacts.length > 0) {
          setContacts(apiContacts);
        }
      }
    }
    if (search !== "") {
      const timeoutId = setTimeout(() => fetchContacts(), 1000);
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => firstData(), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [search]);


  const handleSearch = async (e) => {
    let value = e.target.value;
    setSearch(value);

  };

  const onUpdateContact = (wa_id, attributes, inboxtype) => {
    const { NombreCompleto } = attributes;
    let contactsToUpdate = [];
    if (inboxtype === "sent") {
      //return only the contacts with sentMessage = true
      contactsToUpdate = sentContacts.filter((contact) => {
        return contact.sentMessage;
      });
    } else if (inboxtype === "unread") {
      //return only the contacts with newMessages > 0
      contactsToUpdate = unreadContacts.filter((contact) => {
        return contact.newMessages > 0;
      });
    } else {
      contactsToUpdate = contacts;
    }

    let newContacts = contactsToUpdate.map((contact) => {
      try {
        if (contact.Celular === wa_id) {
          return { ...contact, ...attributes };
        }
      } catch (error) {
        console.log(error + ":" + contact);
      }
      return contact;
    });
    if (inboxtype === "sent") {
      //update also in localstorage
      localStorage.setItem(`contacts/sent${user.id}`, JSON.stringify(newContacts));
      setSentContacts(newContacts);
    } else if (inboxtype === "unread") {
      localStorage.setItem(`contacts/unread${user.id}`, JSON.stringify(newContacts));
      setUnreadContacts(newContacts);
    } else if (inboxtype === "automated") {
      localStorage.setItem(`contacts/automated${user.id}`, JSON.stringify(newContacts));
      setAutomatedContacts(newContacts);
    } else {
      setContacts(newContacts);
    }
  }

  const sortMessages = (inputMessages) => {
    let lastDate = "";
    let index = 0;
    while (inputMessages.length > index) {
      if (inputMessages[index].loadMore)
        return;
      if (inputMessages[index].date) {
        lastDate = inputMessages[index].date;
        return;
      }
      let date = new Date(
        inputMessages[index].entry[0].changes[0].value.messages[0].timestamp * 1000
      );
      let year = date.getFullYear();
      if (year > 2100) {
        date = new Date(
          inputMessages[index].entry[0].changes[0].value.messages[0].timestamp
        );
        year = date.getFullYear();
      }
      let day = date.getDate();
      let month = date.getMonth();
      let currentDate = `${day}/${month + 1}/${year}`;
      if (currentDate !== lastDate) {
        inputMessages.splice(index, 0, { date: currentDate });
        lastDate = currentDate;
      }
      index++;
    }
    return inputMessages;
  };

  const loadContactMessages = async (index, inboxtype) => {
    let updatedContact = {};
    if (inboxtype === "sent") {
      updatedContact = sentContacts[index];
    } else if (inboxtype === "unread") {
      updatedContact = unreadContacts[index];
    } else if (inboxtype === "automated") {
      updatedContact = automatedContacts[index];
    } else {
      updatedContact = contacts[index];
    }
    //load asegurado info
    setLoading(true);
    setCurrentContact({ ...updatedContact, draft: "", inboxtype: inboxtype });
    let messagesFromDB = await getMessages(updatedContact.Celular, 0, user.accessToken);
    let messagesArray = messagesFromDB.flat(2).filter((messageFromDB) => {
      return messageFromDB.entry[0].changes[0].value.messages[0];
    });
    //update updatedContact
    //check if the user.ejecutivo is supervisor
    if (user.ejecutivo && !user.ejecutivo.department_supervisor) {
      updatedContact.newMessages = 0;
    }

    //append to first the load more button
    //check if there is more than 20 messages
    if (messagesArray.length >= 20)
      setMessages([{ loadMore: true }, ...sortMessages(messagesArray)]);
    else
      setMessages(sortMessages(messagesArray));
    onUpdateContact(updatedContact.Celular, { newMessages: updatedContact.newMessages }, inboxtype)
    if (mobileTimer)
      clearInterval(mobileTimer);
    if (isMobile)
      loadTimer();
  };

  const loadMoreContactMessages = async () => {
    setLoading(true);
    let messagesFromDB = await getMessages(currentContact.Celular, messages.length, user.accessToken);
    let messagesArray = messagesFromDB.flat(2).filter((messageFromDB) => {
      return messageFromDB.entry[0].changes[0].value.messages[0];
    });
    if (messagesArray.length > 0) {
      if (messagesArray.length < 20) {
        messagesArray.pop();
      } else {
        messagesArray.unshift({ loadMore: true });
      }
      let messagesOld = messages;
      messagesOld.shift();

      setMessages(messagesArray.concat(messagesOld));
    }
    setLoading(false);
  }


  useEffect(() => {
    if (loading) return;
    setLoading(true);
    firstData();
    let pushID = user.roles.includes("ROLE_ADMIN") ? "" : user.ejecutivo._id;
    socket.on("message" + pushID, (data) => {
      setNewMessage(data);
    });
    socket.on("assignContact" + pushID, (data) => {
      Push.create("Genesis Interno", {
        body: "Te han asignado al cliente " + data.NombreCompleto + " " + data.Celular,
        icon: "/icon.png",
        timeout: 4000,
        onClick: function () {
          window.focus();
          this.close();
        }
      })
    });
    socket.on('pushNotification' + pushID, (data) => {
      try {
        let message = data.message.entry[0].changes[0].value.messages[0];
        let textbody = "";

        if (typeof message.text.body === 'string') {
          textbody = message.text.body.substring(0, 100);
        } else if (message.text.body.name) {
          textbody = message.text.body.name;
        } else if (message.text.body) {
          textbody = JSON.stringify(message.text.body);
        }

        if (!textbody || textbody.length === 0) {
          textbody = "Nuevo mensaje";
        } else if (textbody.length > 100) {
          textbody = textbody.substring(0, 100);
        }

        let contact = message.from;
        Push.create(contact, {
          body: textbody,
          icon: "/icon.png",
          timeout: 4000,
          onClick: function () {
            window.focus();
            this.close();
          }
        })
      } catch (error) {
        console.log(error);
      }
    })
    return () => {

    };
  }, []);

  const loadTimer = () => {


    var temptimer = setTimeout(() => {
      const counterTimer = document.getElementById("timeleft");
      if (counterTimer) {
        setMobileTimer(setInterval(() => {
          let timeleft = counterTimer.innerHTML;
          document.getElementById("timeleftmobile").innerHTML = timeleft;
        }, 10000));
        clearInterval(temptimer);
      }
    }, 5000);
  }

  const loadMoreContacts = async (e, inboxtype) => {
    e.preventDefault();

    setLoading(true);
    let newContacts = [];
    if (inboxtype === "sent") {
      newContacts = await getContacts(user.accessToken, sentContacts.length, inboxtype);
    } else if (inboxtype === "unread") {
      newContacts = await getContacts(user.accessToken, unreadContacts.length, inboxtype);
    } else {
      newContacts = await getContacts(user.accessToken, contacts.length);
    }
    if (newContacts.length > 0) {
      if (newContacts.length < 20) {
        newContacts.pop();
      }
      setContacts(newContacts);
    }
    setLoading(false);
  }

  return (
    <>
      {isMobile && <ResponsiveChatAppBar contact={currentContact} />}
      <div className="shadow-lg rounded-lg">
        <div className="md:flex md:flex-row md:justify-between bg-white">
          {
            <Contacts contacts={contacts} unreadContacts={unreadContacts} automatedContacts={automatedContacts} sentContacts={sentContacts} loadContactMessages={loadContactMessages} search={search} handleSearch={handleSearch} setLoading={setLoading} loadMoreContacts={loadMoreContacts} />
          }
          {
            <div className="w-full px-5 flex flex-col hidden md:block chat-background" id="messagesContainer">
              <div
                className="flex flex-col mt-5 overflow-y-scroll h-[84vh] md:h-[84vh]"
              >
                {messages &&
                  messages.map((message, index) => {
                    if (message.loadMore)
                      return (
                        <div
                          className="text-center text-white text-sm mb-5 cursor-pointer"
                          onClick={loadMoreContactMessages}
                        >
                          <span className="bg-blue-500 rounded-lg p-2 cursor-pointer">
                            Cargar más
                          </span>
                        </div>
                      );
                    if (message.date)
                      return (
                        <div className="text-center text-sm mb-5">
                          <span className="bg-stone-500 rounded-lg p-2">
                            {message.date}
                          </span>
                        </div>
                      );
                    return <Messages key={index} message={message} sendTemplateMessage={sendTemplateMessage} cancelTemplateMessage={cancelTemplateMessage} />;
                  })}
              </div>
              {uploadDetails &&
                (uploadDetails.path ? <div className="mt-auto mb-1 pt-2 bg-white">
                  <Chip
                    variant="outlined"
                    onDelete={onFileUploadCancel}
                    onClick={onFileUploadClick}
                    label={uploadDetails.filename} />
                </div> :
                  <div className="mt-auto mb-1 pt-2 bg-white">
                    Subiendo {uploadDetails.filename} {(uploadDetails.size / 1024).toFixed(2)}KB
                    <LinearProgress />
                  </div>)
              }
              {
                isMobile && <div id="timeleftmobile" class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"></div>
              }
              <div className="mt-auto mb-1 bg-white">
                <MessageInput
                  onSubmit={(e) => handleSubmit(e)}
                  onFileUpload={(e) => handleFileUpload(e)}
                  inputValue={inputValue}
                  setInput={(e) => setInputValue(e.target.value)}
                />
              </div>
            </div>
          }
          <SidebarInfo contact={currentContact} onUpdateContact={onUpdateContact} onSelectTemplate={onSelectTemplate} />
        </div>
      </div>
    </>
  );
}
