import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

const options = [
    {
        value: '',
        label: 'Seleccione',
    },
    {
        value: 'plain',
        label: 'Mensaje con cuerpo personalizado',
    },
    {
        value: 'selecttemplate',
        label: 'Plantillas de mensaje',
    },
    {
        value: 'list',
        label: 'Mensaje de Lista',
    },
    {
        value: 'buttons',
        label: 'Mensajes con botones',
    },
];

function Select({ value, handleId, nodeId, leftHandler }) {
    const { setNodes, setEdges } = useReactFlow();
    const store = useStoreApi();
    const [valueSelect, setValueSelect] = React.useState('');

    let id = parseInt(nodeId) + 1;

    const onChange = (evt) => {
        const { nodeInternals } = store.getState();
        const nodeInternalsSize = nodeInternals.size;
        //get the selected node from nodeId
        const nodeSelectIs = Array.from(nodeInternals.values()).filter((node) => {
            if (node.id === nodeId) {
                return node;
            }
        }
        )[0];

        const getId = () => `${nodeInternalsSize + 1}`;

        const newNode = {
            type: evt.target.value,
            data: {
                selects: {
                    'handle-1': 'smoothstep',
                },
            },
            id: getId(),
            style: {
                background: '#fefefe',
            },
            dragHandle: '.custom-drag-handle',
            sourcePosition: Position.Left,
            targetPosition: Position.Right,
            position: {
                x: nodeSelectIs.position.x + 300,
                y: nodeSelectIs.position.y,
            },
            origin: [0.5, 0.0],
        };
        setValueSelect(evt.target.value);
        setNodes((nds) => nds.concat(newNode));
        //append the new node to the selected node
        setEdges((eds) => eds.concat({ id: getId(), source: nodeId, sourceHandle: handleId, target: newNode.id }));
    };

    return (
        <div className="custom-node__select">
            <div>Tipo de Mensaje</div>
            <Handle type="target" position={Position.Left} id={handleId} />
            <select className="nodrag" onChange={onChange} value={valueSelect}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <Handle type="source" position={Position.Right} id={handleId} />
        </div>
    );
}

function CustomNode({ id, data, leftHandler }) {
    return (
        <>
            <div className="custom-node__header">
                Seleccione un tipo de mensaje o plantilla
            </div>
            <div className="custom-node__body">
                {Object.keys(data.selects).map((handleId) => (
                    <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} leftHandler={leftHandler} />
                ))}
            </div>
        </>
    );
}

export default memo(CustomNode);
