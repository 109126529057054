import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSpinner, faFilter } from "@fortawesome/free-solid-svg-icons";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import DatePickerCustom from "./DatePicker";
import DatePickerDayOfMonth from "./DatePickerDay";
import dayjs from "dayjs";
import { useAuth } from "../services/auth.service";
import axios from "axios";
//create a react component who loads data from axios and render it as a table
export default function Automatizacion() {
    const [polizas, setPolizas] = useState([]);
    const [polizasFiltered, setPolizasFiltered] = useState([]);
    const [filters, setFilters] = useState([]);
    const [templateSent, setTemplateSent] = useState([]);
    const [selectedTemplateSent, setSelectedTemplateSent] = useState([]);
    const [aseguradoras, setAseguradoras] = useState([]);
    const [selectedAseguradoras, setSelectedAseguradoras] = useState([]); //array of objects [{value: "AXA", label: "AXA"}, {value: "MAPFRE", label: "MAPFRE"}
    const [sucursales, setSucursales] = useState([]);
    const [selectedSucursales, setSelectedSucursales] = useState([]); //array of objects [{value: "AXA", label: "AXA"}, {value: "MAPFRE", label: "MAPFRE"}
    const [statuses, setStatuses] = useState([]);
    const [currentMessageStatuses, setCurrentMessageStatuses] = useState([]); //array of objects [{value: "AXA", label: "AXA"}, {value: "MAPFRE", label: "MAPFRE"}
    const [mensajesStatus, setMensajesStatus] = useState([]);
    const [ramos, setRamos] = useState([]);
    const [selectedRamos, setSelectedRamos] = useState([]); //array of objects [{value: "AXA", label: "AXA"}, {value: "MAPFRE", label: "MAPFRE"}
    const [tipos, setTipos] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]); //plain array
    const [sortedAs, setSortedAs] = useState("asc");
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [sentItems, setSentItems] = useState(0);
    const [modalMessageBody, setModalMessageBody] = useState("");
    const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM"));
    const [filterAutomation, setFilterAutomation] = useState({ filtros: [], plantilla: '', hora: 0, dia: 0, monthDiff: 0, yearDiff: 0 });
    const { user, logout } = useAuth();

    const [selectedTipoRenovacion, setSelectedTipoRenovacion] = useState({ id: 1, name: "Por Vencer" });


    const TipoRenovacionList = [
        { id: 1, name: "Por Vencer" },
        { id: 2, name: "Vencidas" },
    ]

    useEffect(() => {
        let templatesSents = [
            ...new Set(templates.map((template) => template.name)),
        ];
        setTemplateSent(templatesSents);
        let aseguradoras = [
            ...new Set(polizas.map((poliza) => poliza.Aseguradora)),
        ];
        setAseguradoras(aseguradoras);
        //get from polizas the distinct statuses
        let statuses = [...new Set(polizas.map((poliza) => poliza.Estatus))];
        setStatuses(statuses);
        // //get from polizas the distinct tipos
        let tipos = [...new Set(polizas.map((poliza) => poliza.Tipo_Renovacion))];
        setTipos(tipos);
        // //get from polizas the distinct ramos
        let ramos = [...new Set(polizas.map((poliza) => poliza.Ramo))];
        setRamos(ramos);
        let sucursales = [...new Set(polizas.map((poliza) => poliza.Sucursal))];
        setSucursales(sucursales);
        let MensajesStatus = [
            ...new Set(polizas.map((poliza) => poliza.Mensaje_Status)),
        ];
        setMensajesStatus(MensajesStatus);
        let currentMessageStatuses = [
            ...new Set(polizas.map((poliza) => poliza.currentMessageStatus)),
        ];
        setCurrentMessageStatuses(currentMessageStatuses);

        setPolizasFiltered(polizas);
    }, [polizas]);

    useEffect(() => {
        if (user) {
            setFilterAutomation({ ...filterAutomation, month_query: selectedDate.split("-")[1], year_query: selectedDate.split("-")[0], tipo_renovacion: selectedTipoRenovacion.id });
            fetch(
                `${process.env.REACT_APP_BACKEND_API}/renovaciones/polizas?Mes=${selectedDate.split("-")[1]
                }&Ano=${selectedDate.split("-")[0]}&TipoRenovacion=${selectedTipoRenovacion.id}${user.roles[0] == 'ROLE_ADMIN' ? '' : '&SoloActivas=1'}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": user.accessToken,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (user.roles[0] == 'ROLE_ADMIN') {
                        setPolizas(data);
                    }
                    else {
                        setPolizas(data.filter((poliza) => poliza.Estatus == 'ACTIVA'));
                    }
                });
        }

        //load templates
        async function loadTemplates() {
            const url = process.env.REACT_APP_BACKEND_API + "/template";
            const config = {
                headers: {
                    "x-access-token": user.accessToken,
                    "Content-Type": "application/json",
                },
            };
            let result = await axios
                .get(url, config)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
            if (result.data)
                setTemplates(result.data);
        }
        loadTemplates();
    }, [selectedDate]);

    const handleDayChange = (date) => {
        setFilterAutomation({ ...filterAutomation, dia: date.$D });
    }

    const handleDateChange = (date) => {
        setPolizasFiltered([]);
        let year = date.$y;
        //let the month append a 0 if it is less than 10
        let month = date.$M + 1 < 10 ? `0${date.$M + 1}` : date.$M + 1;
        setSelectedDate(`${year}-${month}`);
        //calculate the differente in months and years between the selected date and the current date
        let currentYear = dayjs().format("YYYY");
        let currentMonth = dayjs().format("MM");
        let yearDiff = year - currentYear;
        let monthDiff = month - currentMonth;
        setFilterAutomation({ ...filterAutomation, monthDiff: monthDiff, yearDiff: yearDiff });
    };

    const SelectCheckOptions = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const splitURL = (url) => {
        let encoded = "";
        try {
            const split = url.split("/");
            const filename = split[split.length - 1];
            //convert string into urlencode
            encoded = encodeURIComponent(filename);
        } catch (error) {
            encoded = "noexiste.PNG";
        }
        return encoded;
    };


    const createAutomation = (e) => {
        //add the filterAutomation to the endpoint /renovaciones/crear_automatizacion
        fetch(
            `${process.env.REACT_APP_BACKEND_API}/renovaciones/crear_automatizacion`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user.accessToken,
                },
                body: JSON.stringify(filterAutomation),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.insertedId) {
                    alert('Automatizacion creada con exito');
                } else {
                    alert('Error al crear la automatizacion');
                }
            });
    };

    const sendTemplateMessages = (e) => {
        e.preventDefault();
        if (checkedItems.length == 0) {
            return;
        }
        openModal();
        setModalMessageBody("Enviando mensajes...");
        //send each message at 3 seconds interval
        const interval = setInterval(() => {
            const id = checkedItems.shift();
            if (id) {
                const poliza = polizas.find((poliza) => poliza._id == id);
                //check if the poliza has the selected template
                if (
                    poliza.Templates_Sent?.filter(
                        (templatesent) => templatesent.TemplateName == selectedTemplate.name
                    ).length == 0 ||
                    !poliza.Templates_Sent
                )
                    fetch(
                        `${process.env.REACT_APP_BACKEND_API}/messages/sendtemplatemessage`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": user.accessToken,
                            },
                            body: selectedTemplate.type !== "quickreply" ? JSON.stringify({
                                templateID: selectedTemplate.name,
                                to: poliza.Celular,
                                parameters: selectedTemplate.parameters.map((parameter) => {
                                    return { type: "text", value: poliza[parameter.value] };
                                }),
                                header: {
                                    image: selectedTemplate.image,
                                },
                                buttonParametersIndex: selectedTemplate.buttonparameterindex,
                                buttonParameters:
                                    selectedTemplate.buttonparameters.length > 0
                                        ? selectedTemplate.buttonparameters.map(
                                            (buttonParameter) => {
                                                return {
                                                    type: "text",
                                                    value:
                                                        buttonParameter.value == "DocumentoPoliza"
                                                            ? splitURL(poliza[buttonParameter.value])
                                                            : poliza[buttonParameter.value],
                                                };
                                            }
                                        )
                                        : [],
                                subscribedTo: '64d18f536d53886eb99c18f2',
                                subscribedObjectId: poliza._id,
                            }) : JSON.stringify({
                                templateID: selectedTemplate.name,
                                to: poliza.Celular,
                                parameters: selectedTemplate.parameters.map((parameter) => {
                                    return { type: "text", value: poliza[parameter.value] };
                                }),
                                header: {
                                    image: selectedTemplate.image,
                                },
                                quickReplies: true,
                                dataId: poliza._id,
                                buttonParametersIndex: selectedTemplate.buttonparameterindex,
                                buttonParameters:
                                    selectedTemplate.buttonparameters.length > 0
                                        ? selectedTemplate.buttonparameters.map(
                                            (buttonParameter) => {
                                                return {
                                                    type: "payload",
                                                    value: buttonParameter.value,
                                                };
                                            }
                                        )
                                        : [],
                            }),
                        }
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setModalMessageBody(data.error.message);
                                return;
                            }
                            //set sentItems to update the progress bar using the checkedItems.length
                            if (data.messages) {
                                //update the poliza with the message status
                                let actualTemplateMessages = poliza.Templates_Sent;
                                if (actualTemplateMessages) {
                                    //check if there is a TemplateName named Pendiente
                                    if (
                                        actualTemplateMessages.filter(
                                            (template) => template.TemplateName == "Pendiente"
                                        ).length > 0
                                    ) {
                                        //remove the item
                                        actualTemplateMessages = actualTemplateMessages.filter(
                                            (template) => template.TemplateName != "Pendiente"
                                        );
                                    }
                                    actualTemplateMessages.push({
                                        TemplateName: selectedTemplate.name,
                                        Mensaje_Status: "Enviado",
                                        Message_ID: data.messages[0].id,
                                        Message_Timestamp: new Date().getTime(),
                                    });
                                } else {
                                    actualTemplateMessages = [
                                        {
                                            TemplateName: selectedTemplate.name,
                                            Mensaje_Status: "Enviado",
                                            Message_ID: data.messages[0].id,
                                            Message_Timestamp: new Date().getTime(),
                                        },
                                    ];
                                }
                                //wait 2 seconds
                                setTimeout(() => {

                                    fetch(
                                        `${process.env.REACT_APP_BACKEND_API}/renovaciones/polizas/${id}`,
                                        {
                                            method: "PUT",
                                            headers: {
                                                "Content-Type": "application/json",
                                                "x-access-token": user.accessToken,
                                            },
                                            body: JSON.stringify({
                                                Templates_Sent: actualTemplateMessages,
                                                wa_id: poliza["Celular"],
                                                ejecutivoSucursal: poliza["ejecutivoSucursal"],
                                                ejecutivoId: poliza["ejecutivoId"],
                                                Asegurado: poliza["Asegurado"],
                                            }),
                                        }
                                    )
                                        .then((response) => response.json())
                                        .then((data) => {
                                            //update the polizasFiltered
                                            setPolizasFiltered(
                                                polizasFiltered.map((poliza) => {
                                                    if (poliza._id == id) {
                                                        poliza.Templates_Sent = data.Templates_Sent;
                                                    }
                                                    return poliza;
                                                })
                                            );
                                        });
                                }, 2000);

                            }
                            setSentItems(sentItems + 100 / checkedItems.length);
                            console.log(data);
                        });
            } else {
                setSentItems(0);
                setModalMessageBody("Mensajes enviados");
                clearInterval(interval);
                checkAll({ target: { checked: false } });
            }
        }, 3000);
    };

    const selectTemplate = (e) => {
        const templateID = e.target.value;
        let template = templates.find((template) => template._id == templateID);
        if (!template.buttonparameters) template.buttonparameters = [];
        setSelectedTemplate(template);
        //check if filter automation has template and if not assign it
        if (filterAutomation.plantilla == '') {
            setFilterAutomation({ ...filterAutomation, plantilla: template.name });
        } else {
            let tempFilterAutomation = filterAutomation;
            tempFilterAutomation.plantilla = template.name;
            setFilterAutomation(tempFilterAutomation);
        }
    };

    const openModal = () => {
        const modal = document.querySelector(".main-modal");
        modal.classList.remove("fadeOut");
        modal.classList.add("fadeIn");
        modal.style.display = "flex";
    };

    const closeModal = () => {
        const modal = document.querySelector(".main-modal");
        modal.classList.remove("fadeIn");
        modal.classList.add("fadeOut");
        setTimeout(() => {
            modal.style.display = "none";
        }, 500);
    };

    const handleCheck = (e) => {
        const id = e.target.dataset.id;
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item != id));
        }
    };

    const checkAll = (e) => {
        //check all the checkboxes
        console.log(checkedItems);
        const isChecked = e.target.checked;
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let newCheckedItems = [];
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked != isChecked) {
                newCheckedItems.push(checkbox.dataset.id);
                checkbox.checked = isChecked;
            }
        });
        if (!isChecked) {
            setCheckedItems([]);
            return;
        }
        setCheckedItems([...newCheckedItems]);
    };

    const handleSearch = (e) => {
        const search = e.target.value;
        let filtered = polizas.filter((poliza) => {
            if (poliza.Asegurado.toLowerCase().includes(search.toLowerCase())) {
                console.log(poliza.Asegurado);
                return true;
            }
            return false;
        });
        setPolizasFiltered(filtered);
        console.log(filtered);
    };

    const handleMultipleFilter = (e, action) => {
        const filter = filters;
        let filtered = polizasFiltered.filter((poliza) => {
            for (let i = 0; i < filter.length; i++) {
                let filterFlag = false;
                for (const entry of document.getElementsByName(filter[i]).values()) {
                    try {
                        if (poliza[filter[i]] == entry.value) {
                            filterFlag = true;
                        }
                        if (poliza[filter[i]][0]["TemplateName"] == entry.value) {
                            filterFlag = true;
                        }
                    } catch (error) {
                        if (poliza["Mensaje_Status"] == entry.value) {
                            filterFlag = true;
                        }

                    }
                }
                if (!filterFlag) {
                    return false;
                }
            }
            return true;
        });
        setPolizasFiltered(filtered);
        return;
    };

    const handleMultipleFilterMultipleValues = (e, action) => {
        const filter = filters;
        let filtered = polizasFiltered.filter((poliza) => {
            for (let i = 0; i < filter.length; i++) {
                let filterFlag = false;
                for (const entry of document.getElementsByName(filter[i]).values()) {
                    try {
                        if (poliza[filter[i]][0]["TemplateName"] == entry.value) {
                            filterFlag = true;
                        }
                        if (poliza[filter[i]] == entry.value) {
                            filterFlag = true;
                        }
                    } catch (error) {
                        if (poliza["Mensaje_Status"] == entry.value) {
                            filterFlag = true;
                        }
                    }
                }
                if (!filterFlag) {
                    return false;
                }
            }
            return true;
        });
        setPolizasFiltered(filtered);
        return;
    };

    const handleFilter = (e, action) => {
        try {
            const filter = e.target.value;
            const type = e.target.dataset.tipo;
            setFilterAutomation({ ...filterAutomation, filtros: [...filterAutomation.filtros, { tipo: type, valor: filter }] });
            let filtered = polizasFiltered.filter((poliza) => {
                if (poliza[type] == filter) {
                    return true;
                }
                return false;
            });
            setPolizasFiltered(filtered);
            return;
        } catch (error) {
            //handle multiple selection filters
            //check if the filter exists
            if (action.name == "Aseguradora") setSelectedAseguradoras(e);
            if (action.name == "Sucursal") setSelectedSucursales(e);
            if (action.name == "Ramo") setSelectedRamos(e);
            if (action.name == "Mensaje_Status") setSelectedTemplateSent(e);
            const filter = action.name;
            const filterExists = filters.find((item) => {
                if (item == filter) {
                    return true;
                }
                return false;
            });
            setFilterAutomation({ ...filterAutomation, filtros: [...filterAutomation.filtros, { tipo: filter, valor: action.option.value }] });
            if (!filterExists) {
                setFilters([...filters, filter]);
            } else if (filterExists && e.length == 0) {
                setFilters(filters.filter((item) => item != filter));
            }
        }
    };

    const handleFilterSaldo = (e) => {
        const filter = parseInt(e.target.value);
        const type = e.target.dataset.tipo;
        setFilterAutomation({ ...filterAutomation, filtros: [...filterAutomation.filtros, { tipo: type, valor: filter }] });
        let filtered = polizasFiltered.filter((poliza) => {
            if (filter > 0) {
                if (poliza[type] >= filter) {
                    return true;
                }
                return false;
            }
            if (filter < 0) {
                if (poliza[type] <= filter) {
                    return true;
                }
                return false;
            }
            if (filter == 0) {
                if (poliza[type] == filter) {
                    return true;
                }
                return false;
            }
        });
        setPolizasFiltered(filtered);
        return;
    };

    const sortData = (e) => {
        const type = e.target.dataset.tipo;
        if (type == "Saldo") {
            let sorted;
            if (sortedAs == "asc") {
                sorted = polizas.sort((a, b) => {
                    return a.Saldo - b.Saldo;
                });
                setSortedAs("desc");
            } else {
                sorted = polizas.sort((a, b) => {
                    return b.Saldo - a.Saldo;
                });
                setSortedAs("asc");
            }
            setPolizasFiltered(sorted);
        }
        return;
    };

    const resetFilters = () => {
        setPolizasFiltered(polizas);
        //put all select dropdowns on first value
        const selects = document.querySelectorAll("select");
        selects.forEach((select) => {
            select.selectedIndex = 0;
        });
        setSelectedAseguradoras([]);
        setSelectedSucursales([]);
        setSelectedRamos([]);
        setFilters([]);
        setSelectedTemplateSent([]);
        setFilterAutomation({ filtros: [], plantilla: '', hora: 0, dia: 0 });
    };

    return (
        <>
            <div className="bg-white p-8 rounded-md w-full">
                <div className=" flex items-center justify-between pb-6"></div>
                <div>
                    <h2 className="text-gray-600 font-semibold">Automatizacion de Renovaciones</h2>
                    <span className="text-xs">
                        {filterAutomation.filtros.length > 0
                            ? "Filtros seleccionados"
                            : "Seleccione filtros y asigne plantillas automatizadas"}
                    </span>
                </div>
                <div className="text-xs mb-4 h-3">
                    {filterAutomation.filtros.length > 0 && filterAutomation.filtros.map((filtro, index) => {
                        return <span key={index} className="bg-gray-200 text-gray-600 px-2 py-1 rounded-full mr-2">{filtro.tipo} : {filtro.valor}</span>
                    })
                    }
                    {
                        filterAutomation.plantilla != '' && <span className="bg-gray-200 text-gray-600 px-2 py-1 rounded-full mr-2">{filterAutomation.plantilla}</span>
                    }
                    {
                        filterAutomation.dia != 0 && <span className="bg-gray-200 text-gray-600 px-2 py-1 rounded-full mr-2">Ejecutar los dias: {filterAutomation.dia} o su valor mas cercano entre lun y vie</span>
                    }
                    {
                        filterAutomation.monthDiff != 0 && <span className="bg-gray-200 text-gray-600 px-2 py-1 rounded-full mr-2">Meses de diferencia para consulta: {filterAutomation.monthDiff}</span>
                    }
                    {
                        filterAutomation.yearDiff != 0 && <span className="bg-gray-200 text-gray-600 px-2 py-1 rounded-full mr-2">Años de diferencia para consulta: {filterAutomation.yearDiff}</span>
                    }
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex bg-gray-50 items-center p-2 rounded-md">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                            />
                        </svg>
                        <input
                            onChange={handleSearch}
                            className="bg-gray-50 outline-none ml-1 block "
                            type="text"
                            name=""
                            id=""
                            placeholder="Buscar..."
                        />
                    </div>
                    <div className="lg:ml-40 ml-10 space-x-8">
                        <DatePickerDayOfMonth
                            onChange={handleDayChange}
                        />
                        <DatePickerCustom
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                        <select onChange={setSelectedTipoRenovacion}>
                            <option value="">Tipo Renov.</option>
                            {
                                TipoRenovacionList.map((tipoRenovacion) => {
                                    return <option value={tipoRenovacion.id}>{tipoRenovacion.name}</option>
                                })
                            }
                        </select>
                        <select onChange={selectTemplate}>
                            <option value="">Seleccione plantilla</option>
                            {templates.map((template) => {
                                return <option value={template._id}>{template.name}</option>;
                            })}
                        </select>
                        <button
                            onClick={createAutomation}
                            className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
                        >
                            Crear Automatizacion
                        </button>
                        <button
                            className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={resetFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} /> Reset Filters
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-x-auto rounded-lg">
                                <table className="min-w-full">
                                    <thead className="border-b">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                #
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <input type="checkbox" onChange={checkAll} />
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                Asegurado
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <ReactSelect
                                                    options={templateSent.map((templateSent) => {
                                                        return { value: templateSent, label: templateSent };
                                                    })}
                                                    components={SelectCheckOptions}
                                                    onMenuClose={handleMultipleFilterMultipleValues}
                                                    data-tipo="Mensaje_Status"
                                                    className="bg-gray-100"
                                                    onChange={handleFilter}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    name="Mensaje_Status"
                                                    placeholder="Plantilla"
                                                    value={selectedTemplateSent}
                                                />
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100">
                                                <select
                                                    onChange={handleFilter}
                                                    data-tipo="currentMessageStatus"
                                                    className="bg-gray-100"
                                                >
                                                    <option value="1">Estatus</option>
                                                    {currentMessageStatuses.map((status, index) => {
                                                        return (
                                                            <option key={index} value={status}>
                                                                {status}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100">
                                                <select
                                                    onChange={handleFilterSaldo}
                                                    data-tipo="newMessages"
                                                    className="bg-gray-100"
                                                >
                                                    <option value="">Cliente resp</option>
                                                    <option value="1">Con respuesta</option>
                                                    <option value="0">Sin Respuesta</option>
                                                </select>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100">
                                                Chat
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                Cedula
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <ReactSelect
                                                    options={aseguradoras.map((aseguradora) => {
                                                        return { value: aseguradora, label: aseguradora };
                                                    })}
                                                    components={SelectCheckOptions}
                                                    onMenuClose={handleMultipleFilter}
                                                    data-tipo="Aseguradora"
                                                    className="bg-gray-100"
                                                    onChange={handleFilter}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    name="Aseguradora"
                                                    placeholder="Aseguradora"
                                                    value={selectedAseguradoras}
                                                />
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <ReactSelect
                                                    options={sucursales.map((sucursal) => {
                                                        return { value: sucursal, label: sucursal };
                                                    })}
                                                    components={SelectCheckOptions}
                                                    onMenuClose={handleMultipleFilter}
                                                    data-tipo="Sucursal"
                                                    className="bg-gray-100"
                                                    onChange={handleFilter}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    name="Sucursal"
                                                    placeholder="Sucursal"
                                                    value={selectedSucursales}
                                                />
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                Nro Poliza
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <ReactSelect
                                                    options={ramos.map((ramo) => {
                                                        return { value: ramo, label: ramo };
                                                    })}
                                                    components={SelectCheckOptions}
                                                    onMenuClose={handleMultipleFilter}
                                                    data-tipo="Ramo"
                                                    className="bg-gray-100"
                                                    onChange={handleFilter}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    name="Ramo"
                                                    placeholder="Ramo"
                                                    value={selectedRamos}
                                                />
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <select
                                                    onChange={handleFilter}
                                                    data-tipo="Estatus"
                                                    className="bg-gray-100"
                                                >
                                                    <option value="1">Estatus</option>
                                                    {statuses.map((status, index) => {
                                                        return (
                                                            <option key={index} value={status}>
                                                                {status}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                <select
                                                    onChange={handleFilter}
                                                    data-tipo="Tipo_Renovacion"
                                                    className="bg-gray-100"
                                                >
                                                    <option value="1">Tipo Renovacion</option>
                                                    {tipos.map((tipo, index) => {
                                                        return (
                                                            <option key={index} value={tipo}>
                                                                {tipo}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100"
                                            >
                                                Venc
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left bg-gray-100 cursor-pointer"
                                            >
                                                <select
                                                    onChange={handleFilterSaldo}
                                                    data-tipo="Saldo"
                                                    className="bg-gray-100"
                                                >
                                                    <option value="">Saldo</option>
                                                    <option value="-1">Negativo</option>
                                                    <option value="1">Positivo</option>
                                                    <option value="0">Cero</option>
                                                </select>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {polizasFiltered.length > 0 ? (
                                            polizasFiltered.map((poliza, index) => {
                                                return (
                                                    <tr className="border-b">
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            <input
                                                                type="checkbox"
                                                                data-id={poliza._id}
                                                                key={index}
                                                                onChange={handleCheck}
                                                            />
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Asegurado}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                                <span
                                                                    aria-hidden
                                                                    className={
                                                                        "absolute inset-0 opacity-50 rounded-full" +
                                                                        (poliza.Templates_Sent && poliza.Templates_Sent.filter((polizatemplatessent) => polizatemplatessent.TemplateName !== "Pendiente").length > 0
                                                                            ? " bg-green-200"
                                                                            : " bg-orange-200")
                                                                    }
                                                                ></span>
                                                                {
                                                                    //concat array
                                                                    poliza.Templates_Sent
                                                                        ? poliza.Templates_Sent.map(
                                                                            (polizaTemplate, key) => {
                                                                                return (
                                                                                    <p className="relative">
                                                                                        {polizaTemplate.TemplateName + ""}
                                                                                    </p>
                                                                                );
                                                                            }
                                                                        )
                                                                        : "Pendiente"
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.currentMessageStatus ? poliza.currentMessageStatus : "Pendiente"}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.currentMessageStatus !== "unsent" && poliza.currentMessageStatus !== "pending" ? poliza.newMessages + " nuevos mensajes" : "Sin respuesta del cliente"}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.currentMessageStatus ?
                                                                <a href={`/dashboard/chat/${poliza.Celular}`} target="_blank" className="text-blue-500 hover:text-blue-700">Ir al chat</a> :
                                                                'No hay chats para esta poliza'
                                                            }

                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Cedula}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Aseguradora}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Sucursal}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Nro_Poliza}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Ramo}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Estatus}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Tipo_Renovacion}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {new Date(poliza.Vencimiento).getDate() +
                                                                "/" +
                                                                (new Date(poliza.Vencimiento).getMonth() + 1) +
                                                                "/" +
                                                                new Date(poliza.Vencimiento).getFullYear()}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {poliza.Saldo}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faSpinner} spinPulse />
                                            </div>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{ display: "none" }}
                className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster backdrop-blur-sm bg-black/30"
            >
                <div className="border border-gray-200 rounded-lg shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                            <p className="text-2xl font-bold">Enviando Mensajes..</p>
                            <div
                                className="modal-close cursor-pointer z-50"
                                onClick={closeModal}
                            >
                                <svg
                                    className="fill-current text-black"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                >
                                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="my-5">
                            <p>{modalMessageBody}</p>
                            <div className="w-full bg-gray-200 h-1 rounded-lg mt-5">
                                <div
                                    className="bg-blue-600 h-3 rounded-lg"
                                    style={{ width: sentItems + "%" }}
                                ></div>
                            </div>
                        </div>
                        <div className="flex justify-end pt-2">
                            <button
                                onClick={closeModal}
                                className="focus:outline-none modal-close px-4 bg-gray-400 p-3 rounded-lg text-white hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button className="focus:outline-none px-4 bg-teal-500 p-3 ml-3 rounded-lg text-white hover:bg-teal-400">
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
